import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import UI from "../../../../@components/UI";
import Skeleton from "@mui/material/Skeleton";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./styles.css";
const CardItem = ({ item, selected, onSelect }) => {
  const videoRef = React.useRef(null);
  const [loadAttempt, setLoadAttempt] = React.useState(0);

  const hasThumbnail = !!(item?.thumbnail_url && item?.thumbnail_url !== "");
  console.log("item: ", item);
  const reloadVideo = () => {
    console.log("Reloading video for item:", item.id);
    setLoadAttempt(loadAttempt + 1);
  };

  React.useEffect(() => {
    let timer;
    if (item?.file_type.startsWith("video") && !hasThumbnail) {
      timer = setTimeout(() => {
        if (videoRef.current?.readyState !== 4) {
          reloadVideo();
        }
        // If the video hasn't loaded in 5 seconds, reload it
        //reloadVideo();
      }, 1000); // 5000 ms = 5 seconds
    }

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, loadAttempt]); // Depend on item and loadAttempt to reset the timer on retry

  return (
    <Box
      onClick={() => onSelect(item)}
      sx={{
        width: "113px",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        flexDirection: "column",
        position: "relative",
        transition: "all  0.3s ease-in-out",
      }}
    >
      <Box
        sx={{
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            position: "relative",
            borderRadius: (theme) => theme.borderRadius.primary,
            width: "96px",
            margin: "0 auto",
            display: "flex",
            justifyContent: "center",
            height: "100px",
            overflow: "hidden",
            border:
              selected?.id === item?.id
                ? "2px solid #944dff"
                : "2px solid transparent",
            boxShadow:
              selected?.id === item?.id ? "#944dff 0px 0px 15px 0px" : "none",
            transform: selected?.id === item?.id ? "scale(1)" : "none",
            transition: "all 0.3s ease-in-out",
            zIndex: 1,
            "&:hover": {
              boxShadow: "#944dff 0px 0px 15px 0px",
              border: "2px solid #944dff",
              transition: "all 0.3s ease-in-out",
            },
          }}
        >
          {item ? (
            <>
              {(item?.file_type.startsWith("image") || hasThumbnail) && (
                <UI.ImageWithSkeleton
                  sx={{
                    position: "absolute",
                    inset: 0,
                    boxSizing: "border-box",
                    padding: 0,
                    border: "none",
                    margin: "auto",
                    display: "block",
                    width: 0,
                    height: 0,
                    minWidth: "100%",
                    maxWidth: "100%",
                    minHeight: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                  }}
                  src={hasThumbnail ? item.thumbnail_url : item.image_url}
                  width={"100%"}
                  alt={"slider-item"}
                />
              )}
              {item?.file_type.startsWith("video") && !hasThumbnail && (
                <video
                  ref={videoRef}
                  key={`${item.id}-${loadAttempt}`} // Change key to force re-render
                  autoPlay="autoplay"
                  playsInline
                  loop
                  muted
                  poster={
                    "https://hazellabs.infura-ipfs.io/ipfs/QmQ1uMhZzhwDHKiRCqRRACAhPvzgwDsvAox9ciRuTzgpCr"
                  }
                  onLoadedData={() => console.log("Video loaded:", item.id)}
                  onError={() => console.log("Error loading video:", item.id)}
                >
                  <source src={item.database_image_url || item.image_url} />
                </video>
              )}
            </>
          ) : (
            <Skeleton
              height={"100px"}
              width={"100px"}
              variant={"rectangular"}
            />
          )}
        </Box>
        <Box
          sx={{
            mt: { md: "12px", mobile: "6px" },
            justifyContent: "center",
            textAlign: "center",
            overflow: "hidden",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography
              variant={"h3"}
              sx={{
                m: "0 auto",
                maxWidth: "96px",
                fontSize: "11px !important",
                whiteSpace: "wrap",
                lineHeight: "1.5 !important",
                mt: "2px",
              }}
            >
              {item?.name.length > 17
                ? item?.name.slice(0, 17) + "... "
                : item?.name + " "}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const ChooseYourNFTMobile = ({ collection, selected, onSelect }) => {
  const { t } = useTranslation();
  console.log("collection:", collection);
  console.log("test:");
  return (
    <Grid
      item
      md={12}
      mobile={12}
      sx={{
        mt: { md: "5px", mobile: "10px" },
        mb: { md: "15px", mobile: "16px" },
        pt: "0 !important",
      }}
    >
      <Box
        sx={{
          p: "0",
        }}
      >
        {" "}
        <Typography variant={"h4"}>
          {t("brands.collection.collectionPage.chooseYourNft")}
        </Typography>
      </Box>

      <Swiper
        spaceBetween={10}
        slidesPerView={3.3}
        breakpoints={{
          300: {
            slidesPerView: 3.2,
            spaceBetween: 10,
          },
          400: {
            slidesPerView: 3.6,
            spaceBetween: 20,
          },
        }}
        className="chooseYourNFTSwiper"
      >
        {collection?.collection_images.map((item, index) => (
          <SwiperSlide key={item.id}>
            <CardItem
              selected={selected}
              item={item}
              onSelect={(data) => onSelect(data)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Grid>
  );
};

export default ChooseYourNFTMobile;
