import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import UI from "../../@components/UI";
import Grid from "@mui/material/Grid";
import MaterialDialog from "@mui/material/Dialog";
import { Controller, useForm } from "react-hook-form";
import Alert from "@mui/material/Alert";
import useHandleErrors from "../../@components/hooks/useHandleErrors";
import { useSnackbar } from "notistack";
import useService from "../useService";
import { useTranslation } from "react-i18next";

const ResetPassword = ({ toggleOpen }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const { handleApiError } = useHandleErrors();
  const { enqueueSnackbar } = useSnackbar();
  const service = useService();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
    mode: "all",
  });

  const onSubmit = async (data) => {
    setIsLoading(true);

    await service
      .resetPassword(data)
      .then(async () => {
        enqueueSnackbar(t("profile.dialogs.resetPasswordDialog.snackbar"), {
          variant: "success",
        });
        setIsLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        handleApiError(error);
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    toggleOpen && setOpen(!open);
    // eslint-disable-next-line
  }, [toggleOpen]);
  const handleClose = () => {
    setOpen(false);
    reset();
  };
  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          padding: { tablet: "40px 60px 50px", mobile: "28px 10px 30px" },
          width: "650px",
          textAlign: "center",
          margin: { mobile: "10px" },
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent sx={{ p: { mobile: "5px 10px 17px", md: "0px" } }}>
        <CloseIcon
          onClick={() => {
            setOpen(false);
            reset();
          }}
          sx={{
            position: "absolute",
            right: 20,
            top: 30,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />
        <Grid container>
          <Grid item mobile={12} md={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography variant={"h3"} sx={{ textAlign: "center" }}>
                {t("profile.dialogs.resetPasswordDialog.title")}
              </Typography>
              <Box sx={{ mt: "28px" }}>
                <Box sx={{ textAlign: "start" }}>
                  <Typography
                    variant={"h8"}
                    sx={{ textAlign: "start", mb: "5px" }}
                  >
                    {t("profile.dialogs.resetPasswordDialog.emailAddress")}
                  </Typography>
                  <Controller
                    name={"email"}
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        value:
                          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        message: t(
                          "profile.dialogs.resetPasswordDialog.emailPattern"
                        ),
                      },
                    }}
                    render={({ field }) => (
                      <UI.TextField
                        {...field}
                        backgroundcolor={"#ffffff15"}
                        fullWidth
                        placeholder={t(
                          "profile.dialogs.resetPasswordDialog.emailPlaceholder"
                        )}
                      />
                    )}
                  />
                  <Box sx={{ mt: "15px" }}>
                    {errors.email && (
                      <Alert variant="outlined" severity="error">
                        {errors.email?.type === "pattern" &&
                          errors.email.message}
                        {errors.email?.type === "required" &&
                          t(
                            "profile.dialogs.resetPasswordDialog.emailRequired"
                          )}
                      </Alert>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  mt: { md: "34px", mobile: "26px" },
                  px: "15px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <UI.Button
                  disabled={Object.keys(errors).length}
                  sx={{ width: "340px" }}
                  title={t("profile.dialogs.resetPasswordDialog.button")}
                  type={"primary"}
                />
              </Box>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <UI.Busy.FullscreenIndicator show={isLoading}>
        <Typography variant={"subtitle1"}>
          {t("profile.dialogs.resetPasswordDialog.loading")}
        </Typography>
        <Typography variant={"subtitle1"}>
          {t("profile.dialogs.resetPasswordDialog.loading2")}
        </Typography>
      </UI.Busy.FullscreenIndicator>
    </MaterialDialog>
  );
};

export default ResetPassword;
