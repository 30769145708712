import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import UI from "../../@components/UI";

import qvrseWallet from "../../static/nft-101-qvrseWallet.png";
import qvrseNutshell from "../../static/nft-101-qvrseNutshell.png";
import createBrand from "../../static/nft-101-createBrand.png";
import qvrseAi from "../../static/nft-101-qvrseAi.png";
import gamification from "../../static/nft-101-gamification.png";
import qvrseNft from "../../static/nft-101-qvrseNft.png";
import createNft from "../../static/nft-101-createNft.png";
import albums from "../../static/nft-101-albums.png";


const Nft101Footer = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Link to={{ pathname: "https://qvrse-1.gitbook.io/qvrse-docs/" }} target="_blank">
        <UI.Button
          type={"secondary"}
          size={"small"}
          sx={{
            width: { md: "240px", mobile: "300px" },
            height: "46px",
            mt: { md: "50px", mobile: "40px" }
          }}
          title={t("explore.nft101.button.seeAll")}
        />
      </Link>
    </Box>
  );
};

const CommonCard = ({ item }) => {
  const { i18n } = useTranslation();

  return (
    <Link to={{ pathname: item.link }} target="_blank">
      <Card
        sx={{
          maxWidth: 345,
          margin: "auto",
          cursor: "pointer",
          background: (theme) => theme.palette.bgTransparent.primary,
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            background: (theme) => theme.palette.bgTransparent.secondary,
          },
        }}
      >
        <CardMedia
          component="img"
          alt={item.title}
          height="180"
          image={item.image}
        />
        <CardContent
          sx={{
            p: {
              md: "26px 24px 24px !important",
              mobile: "20px 18px 18px !important",
            },
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ mb: "0", fontSize: { lg: "17px", mobile: "15px" } }}
          >
            {i18n.language === "sl" ? item.naslov : item.title}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

const SkeletonCard = () => {
  return (
    <Card
      sx={{
        maxWidth: 345,
        margin: "auto",
        backgroundColor: (theme) => theme.palette.bgTransparent.primary,
      }}
    >
      <Skeleton
        variant="rectangular"
        width="100%"
        height={180}
        sx={{ backgroundColor: (theme) => theme.palette.bgTransparent.primary }}
      />
      <CardContent>
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="40%" />
        <Skeleton variant="text" width="80%" />
      </CardContent>
    </Card>
  );
};

const Nft101 = () => {
  const newsData = [
    {
      title: "Qvrse in a nutshell",
      naslov: "Qvrse na kratko",
      image: qvrseNutshell,
      link: "https://qvrse-1.gitbook.io/qvrse-docs/",
    },
    {
      title: "The Qvrse NFT",
      naslov: "Qvrse NFT",
      image: qvrseNft,
      link: "https://qvrse-1.gitbook.io/qvrse-docs/the-qvrse-nfts",
    },
    {
      title: "NFTs and gamification",
      naslov: "NFT-ji in gamifikacija",
      image: gamification,
      link: "https://qvrse-1.gitbook.io/qvrse-docs/geting-started/nfts-x-gamification/",
    },
    {
      title: "Create a brand profile",
      naslov: "Ustvarite brandov profil",
      image: createBrand,
      link: "https://qvrse-1.gitbook.io/qvrse-docs/geting-started/create-a-brand-profile/",
    },
    {
      title: "Create NFT collection",
      naslov: "Ustvarite NFT zbirko",
      image: createNft,
      link: "https://qvrse-1.gitbook.io/qvrse-docs/geting-started/create-nft-collection/",
    },
    {
      title: "Qvrse AI integration",
      naslov: "Qvrse AI integracija",
      image: qvrseAi,
      link: "https://qvrse-1.gitbook.io/qvrse-docs/geting-started/the-qvrse-ai-integration/",
    },
    {
      title: "The Qvrse wallet",
      naslov: "Qvrse denarnica",
      image: qvrseWallet,
      link: "https://qvrse-1.gitbook.io/qvrse-docs/the-qvrse-wallet/",
    },
    {
      title: "NFT collector's album",
      naslov: "NFT zbirateljski album",
      image: albums,
      link: "https://qvrse-1.gitbook.io/qvrse-docs/geting-started/nft-collectors-album/",
    },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Swiper
        grabCursor={true}
        slidesPerView={1.2}
        spaceBetween={20}
        slidesPerGroup={1}
        autoplay={{
          delay: 6000,
          disableOnInteraction: true,
          pauseOnMouseEnter: false,
        }}
        loop={true}
        pagination={true}
        modules={[Autoplay]}
        breakpoints={{
          500: { slidesPerView: 2, spaceBetween: 20 },
          800: { slidesPerView: 3, spaceBetween: 20 },
          1280: { slidesPerView: 4, spaceBetween: 20 },
          1700: { slidesPerView: 5, spaceBetween: 20 },
        }}
        className="collection-slider"
      >
        {newsData?.length > 0
          ? newsData.map((item, index) => (
            <SwiperSlide key={index}>
              <CommonCard item={item} />
            </SwiperSlide>
          ))
          : [0, 1, 2, 3].map((index) => (
            <SwiperSlide key={index}>
              <SkeletonCard />
            </SwiperSlide>
          ))}
      </Swiper>
      <Nft101Footer />
    </Box>
  );
};

export default Nft101;
