import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import useService from "../useService";
import UI from "../../@components/UI";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Ticket from "./Ticket";
import Skeleton from "@mui/material/Skeleton";
import CustomHooks from "../../@components/hooks";
import collectionIcon from "../../static/collection-icon.png";
import erc721Artifact from "../../abis/contracts/modules/collection/Collection.sol/Collection.json";
import useHandleErrors from "../../@components/hooks/useHandleErrors";
import { useTranslation } from "react-i18next";
import discordLogo from "../../static/discord-icon.png";
import spatialLogo from "../../static/spatial-icon.png";
import albumsLogo from "../../static/album-icon.png";
import PostsSlider from "./PostsSlider";
import UnlockNFTDialog from "./UnlockNFTDialog";
import DiscordAccessDialog from "../dialogs/DiscordAccessDialog";
import { useSnackbar } from "notistack";
import useMediaQuery from "@mui/material/useMediaQuery";
import { encodeFunctionData } from "viem";
import Button from "@mui/material/Button";
import SellDialog from "./SellDialog";
import {
  useSmartAccountClient,
  useSendUserOperation,
} from "@alchemy/aa-alchemy/react";
import OwnedByEOADialog from "./OwnedByEOADialog";
import { useUserContext } from "../../@components/userContext";
import { Breadcrumbs } from "@mui/material";


const CallToActions = ({ collection, discordUtility, spatialUtility }) => {
  const history = useHistory();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: { mobile: "space-between", md: "flex-start" },
      }}
    >
      <Box
        onClick={() => {
          history.push(`/brand/${collection?.dao_id}/album`);
        }}
        sx={{
          mr: { md: "10px", mobile: "15px" },
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "105px",
          height: "118px",
          borderRadius: (theme) => theme.borderRadius.primary,
          backgroundColor: (theme) => theme.palette.bgTransparent.primary,
          "&:hover": {
            transition: "all 0.2s ease",
            backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
          },
        }}
      >
        <Box>
          <Box
            component={"img"}
            src={albumsLogo}
            sx={{
              display: "flex",
              margin: "0 auto",
              mb: "5px",
              width: "52px",
              height: "52px",
              transition: "all 0.5s ease-in-out",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
          />
          <Typography variant={"h9"}>ALBUMS</Typography>
        </Box>
      </Box>
      {spatialUtility && (
        <Box
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.open(spatialUtility.value, "_blank");
          }}
        >
          <Button
            sx={{
              height: "118px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "4px 8px",
              width: "180px",
              transition: "all 0.5s ease-in-out",
              minWidth: "46px !important",
              borderRadius: (theme) => theme.borderRadius.primary,
              backgroundColor: (theme) => theme.palette.bgTransparent.primary,
              "&:hover": {
                transition: "all 0.2s ease",
                backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                mt: "10px",
              }}
            >
              <Box
                component={"img"}
                src={spatialLogo}
                sx={{
                  display: "flex",
                  margin: "0 10px 5px 0",
                  width: "52px",
                  height: "52px",
                  transition: "all 0.5s ease-in-out",
                }}
              />
              <Typography
                variant={"h9"}
                sx={{
                  mr: "10px",
                  overflow: "hidden",
                }}
              >
                {spatialUtility.title}
              </Typography>
            </Box>
            <Typography
              variant={"subtitle4"}
              sx={{ fontSize: "9px", mt: "1px" }}
            >
              POWERED BY SPATIAL
            </Typography>
          </Button>
        </Box>
      )}

      {discordUtility && (
        <DiscordAccessDialog.Action
          item={collection}
          onConfirm={() => { }}
          element={
            <Box
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: { md: "105px", mobile: "118px" },
                height: "118px",
                borderRadius: (theme) => theme.borderRadius.primary,
                backgroundColor: (theme) => theme.palette.bgTransparent.primary,
                "&:hover": {
                  transition: "all 0.2s ease",
                  backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
                },
              }}
            >
              <Box>
                <Box
                  component={"img"}
                  src={discordLogo}
                  sx={{
                    display: "flex",
                    margin: "0 auto",
                    mb: "5px",
                    width: "52px",
                    height: "52px",
                    transition: "all 0.5s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                  }}
                />
                <Typography variant={"h9"}>
                  DISCORD
                </Typography>
              </Box>
            </Box>
          }
        />
      )}
    </Box>
  );
};

const Info = ({ nft, collection, mainUtility }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Box sx={{ ml: { lg: "60px", md: "70px", mobile: "0px" } }}>
      <Box sx={{ mb: { md: "2px", mobile: "0px" } }}>
        {nft?.dao_info?.name ? (
          <Typography variant={"subtitle3"}>{nft?.dao_info?.name}</Typography>
        ) : (
          <Skeleton
            height={"24px"}
            width={"150px"}
            variant={"text"}
          />
        )}
      </Box>
      <Box sx={{ mb: { md: "25px", mobile: "18px" } }}>
        {nft?.nft_name ? (
          <Typography variant={"h3"}>
            {`${nft?.nft_name} #${nft?.nft_id}`}
          </Typography>
        ) : (
          <Skeleton height={"44px"} width={"250px"} variant={"text"} />
        )}
      </Box>
      {nft ? (
        <Box
          sx={{
            mb: { md: "30px", mobile: "20px" },
            display: "flex",
            flexDirection: { lg: "row", mobile: "column" },
          }}
        >
          <UI.UtilityTag utilityType={mainUtility?.type} />
          {collection?.is_imported && (
            <UI.Tag
              sx={{
                ml: { md: "10px", mobile: "0px" },
                backgroundColor: (theme) => theme.palette.tag.green,
              }}
            >
              {t("utilityTag.imported")}
            </UI.Tag>
          )}

          {collection.nft_amount_limit < 999999 && (
            <UI.Tag
              sx={{
                mt: { lg: "0px", mobile: "10px" },
                ml: { lg: "10px", mobile: "0px" },
                backgroundColor: (theme) => theme.palette.tag.green,
              }}
            >
              {t("utilityTag.limited")}
            </UI.Tag>
          )}
        </Box>
      ) : (
        <Box>
          <Skeleton
            sx={{ mb: "20px", display: "flex" }}
            height={"20px"}
            width={"150px"}
            variant={"rectangular"}
          />
          <Skeleton
            sx={{ mb: "20px", display: "flex" }}
            height={"20px"}
            width={"150px"}
            variant={"rectangular"}
          />
        </Box>
      )}
      <Box sx={{ display: { md: "flex", mobile: "none" }, mt: "40px" }}>
        <Box
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            history.push({
              pathname: `/brand/${nft?.dao_id}/collection/${nft?.event_id}`,
              state: { scrollToTop: true },
            });
          }}
          sx={{
            display: "flex",
            cursor: "pointer",
            transition: "all 0.2s ease",
            "&:hover": {
              transform: "scale(1.02)",
            },
            mb: "16px",
          }}
        >
          <Box
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              backgroundColor: "rgba(148, 77, 255, 1);",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              component={"img"}
              src={collectionIcon}
              sx={{ width: "24px" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              variant={"subtitle2"}
              sx={{
                display: "flex",
                alignItems: "center",
                ml: "10px",
                color: (theme) => theme.palette.text.primary,
              }}
            >
              {t("profile.nftPage.viewCollection")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const Index = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const service = useService();
  const [mainUtility, setMainUtility] = React.useState(null);
  const [nft, setNft] = React.useState(null);
  const { handleMetamaskError } = useHandleErrors();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [showTransferDialog, setShowTransferDialog] = React.useState(false);
  const [showOwnedByEOADialog, setShowOwnedByEOADialog] = React.useState(false);
  const [showMoveToWalletDialog, setShowMoveToWalletDialog] =
    React.useState(false);
  const [showSellDialog, setShowSellDialog] = React.useState(false);
  const { user: loggedInUser } = useUserContext();
  const [discordUtility, setDiscordUtility] = React.useState(null);
  const [spatialUtility, setSpatialUtility] = React.useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const { client: smartAccountClient } = useSmartAccountClient({
    type: "MultiOwnerModularAccount",
    gasManagerConfig: {
      policyId:
        window.env.CHAIN === "AMOY"
          ? "2a7bc98c-5422-4d90-a8b1-91de6fdadc0d"
          : "9e29758c-dcb6-43a1-85f5-2acfd5a0c060",
    },
    opts: {
      txMaxRetries: 20,
    },
  });

  const { sendUserOperation } = useSendUserOperation({
    client: smartAccountClient,
    onSuccess: ({ hash, request }) => {
      setLoading(false);
      enqueueSnackbar("NFT was successfully transferred", {
        variant: "success",
      });
    },
    onError: (error) => {
      setLoading(false);
      handleMetamaskError(error);
    },
    waitForTxn: true,
  });

  const { data: collection, refetch: refetchCollection } = CustomHooks.useFetch(
    ["myNFT", id],
    () => service.nft(id)
  );

  useEffect(() => {
    if (collection) {
      setNft(collection);
      //Check if any of the utilities type is discord_access and add it to the state
      const discordUtility = collection?.utilities.find(
        (utility) => utility.type === "discord_access"
      );
      setDiscordUtility(discordUtility);

      let spatialUtilityField = null;

      collection?.utilities.forEach((utility) => {
        utility.custom_info_fields?.forEach((field) => {
          if (
            field.input_type === "button" &&
            field.value.startsWith("https://www.spatial.io/")
          ) {
            spatialUtilityField = field;
          }
        });
      });
      nft?.utilities.forEach((utility) => {
        utility.custom_info_fields?.forEach((field) => {
          if (
            field.input_type === "button" &&
            field.value.startsWith("https://www.spatial.io/")
          ) {
            spatialUtilityField = field;
            spatialUtilityField.utility = utility;
          }
        });
      });

      setSpatialUtility(spatialUtilityField);

      setMainUtility(
        collection?.utilities.find((utility) => utility.is_main_utility)
      );
    }
    // eslint-disable-next-line
  }, [collection]);

  const transferTicket = async (ticket, recipientAddress) => {
    setLoading(true);
    await sendUserOperation({
      uo: {
        target: ticket?.nft_address,
        data: encodeFunctionData({
          abi: erc721Artifact.abi,
          functionName: "transferFrom",
          args: [
            smartAccountClient?.getAddress(),
            recipientAddress,
            ticket?.nft_id,
          ],
        }),
      },
    });
  };

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <UI.Busy.FullscreenIndicator show={loading}>
        <Typography variant={"subtitle1"}>
          {t("profile.nftPage.loading")}
        </Typography>
        <Typography variant={"subtitle1"}>
          {t("profile.nftPage.loading2")}
        </Typography>
      </UI.Busy.FullscreenIndicator>
      <Paper elevation={0} sx={{ width: "100%", background: "transparent" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", mobile: "column" },
            justifyContent: { md: "space-between", mobile: "center" },
            px: { lg: "10px", md: "70px", mobile: "0px" },
            pb: { lg: "20px", md: "15px", mobile: "20px" },
            pt: { lg: "20px", md: "0px", mobile: "0px" },
          }}
        >
          <Box
            sx={{
              display: { md: "flex", mobile: "flex" },
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "block" }}>
              <Breadcrumbs aria-label="breadcrumb">
                <Box
                  underline="hover"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    transition: "all 0.2s ease-in-out",
                    "&:hover": {
                      cursor: "pointer",
                      color: (theme) => theme.palette.text.primary,
                    },
                  }}
                  onClick={() => {
                    history.push(`/profile`);
                  }}
                >
                  {t("breadcrumbs.myNFTs")}
                </Box>
                {nft ? (
                  <Typography
                    sx={{ display: "flex", alignItems: "center", fontFamily: "Open Sans", fontSize: "14px", fontWeight: "600" }}
                    color="text.primary"
                  >
                    {nft?.nft_name}
                  </Typography>
                ) : (
                  <Skeleton variant="text" width={100} />
                )}
              </Breadcrumbs>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            paddingLeft: { mobile: "0px", md: "60px", lg: "0px", },
            paddingRight: { mobile: "0px", md: "0px" },
          }}
        >
          <Grid
            sx={{ p: { md: "10px", mobile: "0px" } }}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={4} mobile={12}>
              <Box
                sx={{
                  display: "flex",
                  mb: { md: "40px", mobile: "20px" },
                  justifyContent: { mobile: "unset", md: "unset" },
                }}
              >
                <Box
                  sx={{
                    mr: { md: "0px", mobile: "15px" },
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Ticket
                    brandName={nft?.dao_info?.name}
                    brandAvatar={nft?.dao_info?.avatar_url}
                    name={nft?.nft_name}
                    name_slo={nft?.nft_name_slo || null}
                    fileType={nft?.image_file_type}
                    data={nft?.nft_image_url}
                    nft={nft}
                    animationUrl={nft?.nft_animation_url || null}
                    mainUtility={mainUtility}
                    onTransfer={() => {
                      if (
                        nft?.owner_address.toLowerCase() ===
                        loggedInUser?.smart_address.toLowerCase()
                      ) {
                        setShowTransferDialog(true);
                      } else {
                        setShowOwnedByEOADialog(true);
                      }
                    }}
                    onMoveToWallet={() => setShowMoveToWalletDialog(true)}
                    onListToMarketplace={() => {
                      setShowSellDialog(true);
                    }}
                  />

                  <UI.TransferDialog
                    open={showTransferDialog}
                    onClose={() => setShowTransferDialog(false)}
                    onConfirm={async (address) => {
                      setShowTransferDialog(false);
                      await transferTicket(nft, address);
                    }}
                  />
                  <OwnedByEOADialog
                    eoa={nft?.owner_address}
                    open={showOwnedByEOADialog}
                    onClose={() => setShowOwnedByEOADialog(false)}
                  />

                  <UnlockNFTDialog
                    nftId={nft?.id}
                    open={showMoveToWalletDialog}
                    onClose={() => setShowMoveToWalletDialog(false)}
                    onSuccess={() => {
                      refetchCollection();
                    }}
                    nftVaultAddress={nft?.nft_vault_address}
                  />
                </Box>
                <Box sx={{ display: { md: "none", mobile: "block" } }}>
                  <Info
                    nft={nft}
                    collection={collection}
                    mainUtility={mainUtility}
                  />
                </Box>
              </Box>
            </Grid>
            {isMobile && (
              <Grid mobile={12}>
                <Box
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    history.push({
                      pathname: `/brand/${nft?.dao_id}/collection/${nft?.event_id}`,
                      state: { scrollToTop: true },
                    });
                  }}
                  sx={{
                    display: "flex",
                    cursor: "pointer",
                    transition: "all 0.2s ease",
                    "&:hover": {
                      transform: "scale(1.02)",
                    },
                    mb: { tablet: "10px", mobile: "5px" },
                    mt: { tablet: "7px", mobile: "2px" },
                  }}
                >
                  <Box
                    sx={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "50%",
                      backgroundColor: "rgba(148, 77, 255, 1);",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={collectionIcon}
                      sx={{ width: "20px" }}
                    />
                  </Box>
                  <Typography
                    variant={"subtitle3"}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      ml: "9px",
                      color: (theme) => theme.palette.text.primary,
                    }}
                  >
                    {t("profile.nftPage.viewCollection")}
                  </Typography>
                </Box>
              </Grid>
            )}

            <Grid item md={8} mobile={12}>
              {!isMobile && (
                <Box
                  sx={{
                    justifyContent: "space-between",
                    display: "flex",
                  }}
                >
                  <Info
                    nft={nft}
                    collection={collection}
                    mainUtility={mainUtility}
                  />

                  <CallToActions
                    collection={nft}
                    discordUtility={discordUtility}
                    spatialUtility={spatialUtility}
                  />
                </Box>
              )}
              <PostsSlider collection={nft} />
            </Grid>

            {isMobile && (
              <Grid
                item
                md={12}
                mobile={12}
                sx={{ mb: "30px", display: "flex" }}
              >
                <CallToActions
                  collection={nft}
                  discordUtility={discordUtility}
                  spatialUtility={spatialUtility}
                />
              </Grid>
            )}
            <Grid item md={12} mobile={12} sx={{ pt: "0 !important" }}>
              <Box sx={{ mb: "10px" }}>
                <Typography variant={"h4"}>
                  {t("profile.nftPage.perks")}
                </Typography>
              </Box>
              <UI.Utilities
                showRedeemed
                gasFeesPayedBySystem={nft?.free_nft_gas_fee_payed_by_system}
                utilities={nft?.utilities}
                currency={nft?.currency}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <SellDialog
        open={showSellDialog}
        onClose={() => setShowSellDialog(false)}
        onSuccess={() => {
          setShowSellDialog(false);
        }}
        brandName={nft?.dao_info?.name}
        daoId={nft?.dao_id}
        collectionId={nft?.event_id}
        tokenId={nft?.nft_id}
        tokenAddress={nft?.nft_address}
      />
    </Box>
  );
};

export default Index;
