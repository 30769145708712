import React from "react";
import UI from "../../../../../../@components/UI";
import FrontSide from "../FrontSide";
import BackSide from "./BackSide";
import Lottie from "lottie-react";
import confettiAnimation from "../../../../../../static/confetti-animation.json";
import { Box } from "@material-ui/core";

const Card = ({ refetch, data, DAO, item, isEditing }) => {
  const confettiRef = React.useRef(null);
  const confettiRef2 = React.useRef(null);

  const triggerConfetti = () => {
    confettiRef.current?.goToAndPlay(0);
    confettiRef2.current?.goToAndPlay(0);
  };

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <UI.AlbumFlipCard
        isConfirmed={item.album_card.length !== 0}
        frontComponent={<FrontSide item={item} />}
        backComponent={
          <BackSide
            triggerConfetti={() => triggerConfetti()}
            refetch={refetch}
            data={data}
            DAO={DAO}
            item={item}
            isEditing={isEditing}
          />
        }
      />
      <Lottie
        lottieRef={confettiRef}
        autoplay={false}
        loop={false}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          pointerEvents: "none",
        }}
        resizeMode="cover"
        animationData={confettiAnimation}
      />
    </Box>
  );
};

export default Card;
