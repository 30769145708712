import React from "react";
import Paper from "@mui/material/Paper";
import List from "./List";
import useService from "../../../useService";
import CustomHooks from "../../../../../../@components/hooks";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import UI from "../../../../../../@components/UI";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";

const Index = ({ DAO }) => {
  const { albumId } = useParams();
  const { t } = useTranslation();
  const service = useService(DAO?.id);
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const {
    data: cards,
    refetch: updateCards,
    isLoading,
  } = CustomHooks.useFetch(["albums"], () => service.cards(albumId));

  const { mutate: removeCard } = CustomHooks.usePost(service.removeCard, {
    onSuccess: async () => {
      enqueueSnackbar(t("brands.albums.createCard.snackbar"), {
        variant: "success",
      });
      await updateCards(albumId);
    },
  });

  return (
    <Paper
      sx={{
        width: "100%",
        py: { md: "50px", mobile: "20px" },
        background: "transparent",
      }}
    >
      {isLoading && (
        <UI.Busy.Indicator
          width={isMobile ? "100px" : "160px"}
          height={isMobile ? "100px" : "160px"}
        />
      )}
      {!isLoading && (
        <List
          cards={cards}
          onRemoveCard={(cardId) => {
            removeCard({ albumId, cardId: cardId });
          }}
        />
      )}
    </Paper>
  );
};

export default Index;
