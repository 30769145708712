//import { useHistory } from "react-router-dom";
import { useDrag } from "react-dnd";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import React from "react";

const Item = ({
  contentType,
  cardType,
  selected,
  index,
  contentData,
  onSelect,
  isFirstItem,
  collection,
}) => {
  //const history = useHistory();
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: cardType.toString(),
      item: { index, nft: collection },
      canDrag: !collection.not_owned,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    }),
    [collection]
  );
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        position: "relative",
        borderRadius: (theme) => theme.borderRadius.primary,
        cursor: "pointer",
        width: isMobile ? "70px" : "85px",
        height: isMobile ? "70px" : "85px",
        display: "flex",
        opacity: collection.not_owned ? 0.5 : 1,
        alignItems: "center",
        overflow: "hidden",
        background: "transparent",
        marginLeft: isFirstItem ? "0px" : isMobile ? "5px" : "10px",
        marginRight: isMobile ? "5px" : "10px",
        transition: "all 0.3s ease-in-out",
        "&:hover": {
        },
      }}
      ref={dragRef}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onSelect(index);
      }}
      onTouchEnd={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onSelect(index);
      }}
    >
      {contentType?.startsWith("video") && (
        <video
          playsInline
          autoplay="autoplay"
          style={{
            width: "100%",
            height: "100%",
            opacity: opacity,
            borderRadius: "5px",
            objectFit: "cover",
            objectPosition: "center",
          }}
          loop
        >
          <source src={contentData} />
        </video>
      )}
      {contentType?.startsWith("image") && (
        <Box
          component={"img"}
          src={contentData}
          sx={{
            width: "100%",
            height: "100%",
            opacity: opacity,
            borderRadius: "5px",
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      )}
      {/*{collection.not_owned && (*/}
      {/*  <Box*/}
      {/*    // onClick={() => {*/}
      {/*    //   history.push(*/}
      {/*    //     `/brand/${collection?.dao_id}/collection/${collection.collection_id}`*/}
      {/*    //   );*/}
      {/*    // }}*/}
      {/*    sx={{*/}
      {/*      position: "absolute",*/}
      {/*      bottom: 0,*/}
      {/*      left: 0,*/}
      {/*      backgroundColor: "#944DFF",*/}
      {/*      width: "36px",*/}
      {/*      height: "36px",*/}
      {/*      borderRadius: "0px 20px 0px 0px",*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Box*/}
      {/*      component={"img"}*/}
      {/*      src={shopingCartIcon}*/}
      {/*      sx={{*/}
      {/*        width: "16px !important",*/}
      {/*        position: "absolute",*/}
      {/*        top: "13px",*/}
      {/*        left: "7px",*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </Box>*/}
      {/*)}*/}
    </Box>
  );
};

export default Item;
