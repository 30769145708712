import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import UI from "../../../../@components/UI";
import { Controller, useForm } from "react-hook-form";
import useService from "./useService";
import { useSnackbar } from "notistack";
import InputAdornment from "@mui/material/InputAdornment";
import linkedInIcon from "../../../../static/linkedin.png";
import discordIcon from "../../../../static/discord.png";
import twitterIcon from "../../../../static/twitter.png";
import instagramIcon from "../../../../static/instagram.png";
import telegramIcon from "../../../../static/telegram.png";
import facebookIcon from "../../../../static/facebook.png";
import youtubeIcon from "../../../../static/youtube-icon.png";
import spotifyIcon from "../../../../static/spotify-icon.png";
import CustomHooks from "../../../../@components/hooks";
import { useTranslation } from "react-i18next";
import SwitchButton from "./SwitchButton";

const useStyles = makeStyles((theme) => ({
  backButtonOuter: {
    "&:hover .backArrow": {
      transform: "translateX(-50%)",
    },
  },
  inputOuter: {
    marginTop: "25px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const Index = ({ DAO, refetch }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const service = useService(DAO?.id);
  const classes = useStyles();
  const [avatarPreview, setAvatarPreview] = React.useState(null);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      name: DAO?.name,
      webpage_url: DAO?.webpage_url,
      description: DAO?.description,
      avatar_url: DAO?.avatar_url,
      whitepaper_url: DAO?.whitepaper_url,
      discord: DAO?.discord,
      linkedin: DAO?.linkedin,
      instagram: DAO?.instagram,
      reddit: DAO?.reddit,
      telegram: DAO?.telegram,
      twitter: DAO?.twitter,
      facebook: DAO?.facebook,
    },
  });

  React.useEffect(() => {
    if (DAO) {
      reset({
        name: DAO?.name,
        webpage_url: DAO.webpage_url,
        description: DAO.description,
        avatar_url: DAO.avatar_url,
        whitepaper_url: DAO.whitepaper_url,
        discord: DAO.discord,
        instagram: DAO.instagram,
        reddit: DAO.reddit,
        linkedin: DAO.linkedin,
        telegram: DAO.telegram,
        twitter: DAO.twitter,
        facebook: DAO.facebook,
        youtube: DAO.youtube,
        spotify: DAO.spotify,
      });

      setAvatarPreview({
        ipfs: DAO?.avatar_url,
        file: { type: "image/" },
      });
    }
    // eslint-disable-next-line
  }, [DAO]);

  const { mutate: saveSettings } = CustomHooks.usePost(service.save, {
    onSuccess: () => {
      enqueueSnackbar("Settings succesfully updated", {
        variant: "success",
      });
      refetch();
    },
  });

  const { mutate: toggleVisibility } = CustomHooks.usePost(
    service.toggleVisibility,
    {
      onSuccess: () => {
        enqueueSnackbar("Brand visibility successfully changed", {
          variant: "success",
        });
      },
    }
  );

  const onSubmit = async (form) => {
    const data = {
      ...form,
      avatar_url: form?.avatar_url?.ipfs,
      whitepaper_url: form?.whitepaper_url?.ipfs,
    };

    saveSettings(data);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: { lg: "70px", md: "50px", tablet: "15px", mobile: "10px" },
          pb: "0px",
          pt: { lg: "30px", md: "10px", mobile: "0px" },
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography variant={"h3"}>
            {t("brands.settings.general.title")}
          </Typography>
        </Box>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ padding: { lg: "5px 70px", md: "5px 50px", tablet: "0 15px", mobile: "0 10px" }}}>
          <Typography variant={"subtitle2"} sx={{ mb:  { lg: "35px", md: "30px", mobile: "25px" } }}>
            {t("brands.settings.general.subtitle")}
          </Typography>
          <Box>
            <Box sx={{ display: "flex", mb: "8px" }}>
              <Typography variant={"h6"}>
                {t("brands.settings.general.avatar.title")}
              </Typography>
              <UI.Tooltip>
                {" "}
                {t("brands.settings.general.avatar.tooltip")}
              </UI.Tooltip>
            </Box>
            <Controller
              name={"avatar_url"}
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <UI.Upload
                  {...field}
                  id={"avatar"}
                  noPadding
                  noHeight
                  avatarPreviewWidth={"150px"}
                  avatarPreviewHeight={"150px"}
                  showAvatarPreview
                  defaultValue={avatarPreview}
                  title={t("brands.settings.general.avatar.uploadTitle")}
                  acceptFiles={"image/jpeg,image/gif,image/png"}
                />
              )}
            />
          </Box>
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "8px" }}>
              <Typography variant={"h6"}>
                {t("brands.settings.general.name.title")}
              </Typography>
            </Box>

            <Controller
              name={"name"}
              control={control}
              rules={{ required: true }}
              render={({ field }) => <UI.TextField {...field} />}
            />
          </Box>
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "8px" }}>
              <Typography variant={"h6"}>
                {t("brands.settings.general.about.title")}
              </Typography>
            </Box>

            <Controller
              name={"description"}
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <UI.TextField
                  multiline
                  rows={6}
                  {...field}
                  placeholder={t("brands.settings.general.about.placeholder")}
                />
              )}
            />
          </Box>

          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "8px" }}>
              <Typography variant={"h6"}>
                {t("brands.settings.general.webpage.title")}
              </Typography>
            </Box>

            <Controller
              name={"webpage_url"}
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <UI.TextField
                  {...field}
                  placeholder={t("brands.settings.general.webpage.placeholder")}
                />
              )}
            />
          </Box>
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "8px" }}>
              <Typography variant={"h6"}>Socials</Typography>
            </Box>
            <Controller
              name={"linkedin"}
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <UI.TextField
                  startAdornment={
                    <InputAdornment position="start">
                      <Box
                        component={"img"}
                        src={linkedInIcon}
                        alt="linkedin-icon"
                        width={"35px"}
                        height={"35px"}
                        sx={{ mr: "3px" }}
                      />
                    </InputAdornment>
                  }
                  {...field}
                  start
                  placeholder={"URL"}
                />
              )}
            />
          </Box>

          <Box className={classes.inputOuter} sx={{ mt: "0px !important" }}>
            <Controller
              name={"discord"}
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <UI.TextField
                  {...field}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box
                        component={"img"}
                        src={discordIcon}
                        alt="discord-icon"
                        width={"35px"}
                        height={"35px"}
                        sx={{ mr: "3px" }}
                      />
                    </InputAdornment>
                  }
                  placeholder={"URL"}
                />
              )}
            />
          </Box>

          <Box className={classes.inputOuter} sx={{ mt: "0px !important" }}>
            <Controller
              name={"twitter"}
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <UI.TextField
                  {...field}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box
                        component={"img"}
                        src={twitterIcon}
                        alt="twitter-icon"
                        width={"35px"}
                        height={"35px"}
                        sx={{ mr: "3px" }}
                      />
                    </InputAdornment>
                  }
                  placeholder={"URL"}
                />
              )}
            />
          </Box>

          <Box className={classes.inputOuter} sx={{ mt: "0px !important" }}>
            <Controller
              name={"telegram"}
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <UI.TextField
                  {...field}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box
                        component={"img"}
                        src={telegramIcon}
                        alt="telegram-icon"
                        width={"35px"}
                        height={"35px"}
                        sx={{ mr: "3px" }}
                      />
                    </InputAdornment>
                  }
                  placeholder={"URL"}
                />
              )}
            />
          </Box>

          <Box className={classes.inputOuter} sx={{ mt: "0px !important" }}>
            <Controller
              name={"instagram"}
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <UI.TextField
                  {...field}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box
                        component={"img"}
                        src={instagramIcon}
                        alt="instagram-icon"
                        width={"35px"}
                        height={"35px"}
                        sx={{ mr: "3px" }}
                      />
                    </InputAdornment>
                  }
                  placeholder={"URL"}
                />
              )}
            />
          </Box>

          <Box className={classes.inputOuter} sx={{ mt: "0px !important" }}>
            <Controller
              name={"facebook"}
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <UI.TextField
                  {...field}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box
                        component={"img"}
                        src={facebookIcon}
                        alt="facebook-icon"
                        width={"35px"}
                        height={"35px"}
                        sx={{ mr: "3px" }}
                      />
                    </InputAdornment>
                  }
                  placeholder={"URL"}
                />
              )}
            />
          </Box>
          <Box className={classes.inputOuter} sx={{ mt: "0px !important" }}>
            <Controller
              name={"youtube"}
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <UI.TextField
                  {...field}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box
                        component={"img"}
                        src={youtubeIcon}
                        alt="youtube-icon"
                        width={"35px"}
                        height={"35px"}
                        sx={{ mr: "3px" }}
                      />
                    </InputAdornment>
                  }
                  placeholder={"URL"}
                />
              )}
            />
          </Box>
          <Box className={classes.inputOuter} sx={{ mt: "0px !important" }}>
            <Controller
              name={"spotify"}
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <UI.TextField
                  {...field}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box
                        component={"img"}
                        src={spotifyIcon}
                        alt="spotify-icon"
                        width={"35px"}
                        height={"35px"}
                        sx={{ mr: "3px" }}
                      />
                    </InputAdornment>
                  }
                  placeholder={"URL"}
                />
              )}
            />
          </Box>
          <Box
            sx={{
              mt: "30px",
            }}
          >
            <Box sx={{ display: "flex", mb: "8px" }}>
              <Typography variant={"h6"}>
                {t("brands.settings.general.visibility.title")}
              </Typography>
            </Box>
            {DAO && (
              <SwitchButton
                label={t("brands.settings.general.visibility.toggle")}
                defaultChecked={DAO?.is_private !== true}
                handleChange={() => {
                  toggleVisibility();
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              mt: "30px",
            }}
          >
            <Box sx={{ display: "flex", mb: "8px" }}>
              <Typography variant={"h6"}>
                {t("brands.settings.general.document.title")}
              </Typography>
            </Box>
            <Controller
              name={"whitepaper_url"}
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <UI.Upload
                  {...field}
                  noHeight
                  id={"whitepaper"}
                  title={t("brands.settings.general.document.uploadTitle")}
                  subtitle={t("brands.settings.general.document.subtitle")}
                  acceptFiles={"application/pdf"}
                />
              )}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            mt: { md: "30px", mobile: "20px" },
            pb: { md: "60px", mobile: "40px" },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "210px", mt: { md: "0px", mobile: "20px" } }}>
            <UI.Button title={t("buttons.saveChanges")} type={"primary"} />
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default Index;
