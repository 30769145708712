import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useFormData } from "../context";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../../@components/UI";
import Alert from "@mui/material/Alert";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useUserContext } from "../../../../../@components/userContext";
import photoIcon from "../../../../../static/select-photo-icon.png";
import videoIcon from "../../../../../static/select-video-icon.png";
import surpriseBagIcon from "../../../../../static/select-surprise-bag-icon.png";
import chooseNftIcon from "../../../../../static/select-choose-nft-icon.png";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import SupriseBag from "./SupriseBag";
import ChooseYourNFT from "./ChooseYourNFT";
import StoryMedia from "./StoryMedia";
import { useTranslation } from "react-i18next";
import { Breadcrumbs } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";

const useStyles = makeStyles((theme) => ({
  inputOuter: {
    marginBottom: "15px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  backButton: {
    textAlign: "left",
    fontFamily: "Quantico",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "13px",
    lineHeight: "20px",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
  },
  backButtonOuter: {
    "&:hover .backArrow": {
      transform: "translateX(-50%)",
    },
  },
}));

const Index = ({ DAO, draft, onSave, nextStep }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const { user: loggedInUser } = useUserContext();
  const { data, setFormValues } = useFormData();
  const [totalMintLimit, setTotalMintLimit] = React.useState(0);

  const {
    handleSubmit,
    control,
    register,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      basic_info: {
        picture_url: data?.basic_info?.picture_url,
        nft_animation_url: data?.basic_info?.nft_animation_url,
        random_images: true,
        collection_images: data?.basic_info?.collection_images,
        manual_nft_selection: data?.basic_info?.manual_nft_selection,
        type_of_media: data?.basic_info?.type_of_media || "photo",
        name: data?.basic_info?.name,
        symbol: data?.basic_info?.symbol,
        supply: data?.basic_info?.supply || 999999,
        description: data?.basic_info?.description,
        scheduled_date_time: null,
        visible: data?.basic_info?.visible,
        is_scheduled_later: false,
        is_supply_unlimited: true,
      },
    },
    mode: "onSubmit",
  });

  const selectedScheduleDisplay = watch("basic_info.is_scheduled_later");
  const isSelectedSupplyTypeUnlimited = watch("basic_info.is_supply_unlimited");
  const selectedRandomMint = watch("basic_info.random_images");
  const selectedVisible = watch("basic_info.visible");
  const selectedMedia = watch("basic_info.type_of_media");

  const handleMedia = (event, newSelectedMedia) => {
    reset({
      basic_info: {
        picture_url: null,
        nft_animation_url: null,
        random_images: true,
        collection_images: null,
        manual_nft_selection: null,
        name: null,
        symbol: null,
        supply: newSelectedMedia === "suprise-bag" ? null : 999999,
        description: null,
        scheduled_date_time: null,
        visible: true,
        is_scheduled_later: false,
        is_supply_unlimited: true,
      },
    }); //Reset form when media type is changed.
    setValue("basic_info.type_of_media", newSelectedMedia);

    if (newSelectedMedia === "suprise-bag") {
      setValue("basic_info.is_supply_unlimited", false);
      setValue("basic_info.has_multiple_images", true);
      setValue("basic_info.picture_url", {
        ipfs: "https://hazellabs.infura-ipfs.io/ipfs/QmQ1uMhZzhwDHKiRCqRRACAhPvzgwDsvAox9ciRuTzgpCr",
        file: { type: "image/png" },
      });
    } else {
      setValue("basic_info.has_multiple_images", false);
    }

    if (newSelectedMedia === "choose-nft") {
      setValue("basic_info.has_multiple_images", true);
      setValue("basic_info.manual_nft_selection", true);
      setValue("basic_info.picture_url", {
        ipfs: "https://hazellabs.infura-ipfs.io/ipfs/QmQ1uMhZzhwDHKiRCqRRACAhPvzgwDsvAox9ciRuTzgpCr",
        file: { type: "image/png" },
      });
    } else {
      setValue("basic_info.manual_nft_selection", false);
    }
  };

  React.useEffect(() => {
    if (selectedMedia === "suprise-bag" && totalMintLimit > 0) {
      setValue("basic_info.supply", totalMintLimit);
    }
  }, [totalMintLimit, selectedMedia, setValue]);

  // Reset form with draft data.
  React.useEffect(() => {
    if (draft) {
      setFormValues(draft.data);
      reset({
        basic_info: draft.data.basic_info,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft]);

  const onSubmit = async (values, action) => {
    switch (action) {
      case "save_and_continue": {
        await onSave(values, false);
        nextStep();

        break;
      }
      case "save": {
        await onSave(values, true);
        break;
      }
      default: {
        await onSave(values, true);
        break;
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <form
      onKeyDown={handleKeyDown}
      onSubmit={handleSubmit((values) => onSubmit(values, "save_and_continue"))}
    >
      <Box
        sx={{
          mt: { md: "60px", mobile: "20px" },
          p: { md: "0px 65px", mobile: "10px 10px 0" },
        }}
      >
        <Typography variant={"subtitle1"} sx={{ opacity: 1 }}>
          {t("brands.collection.create.basicInfo.surtitle")}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: { md: "15px", mobile: "30px" },
          p: { md: "40px 65px", mobile: "0px 10px" },
          md: "10px",
        }}
      >
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: "30px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                cursor: "pointer",
                color: (theme) => theme.palette.text.primary,
              },
            }}
            onClick={() => {
              history.push(`/brand/${DAO.id}`);
            }}
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            {t("breadcrumbs.home")}
          </Box>
          <Box
            underline="hover"
            sx={{
              display: "flex",
              alignItems: "center",
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                cursor: "pointer",
                color: (theme) => theme.palette.text.primary,
              },
            }}
            onClick={() => {
              history.push(`/brand/${DAO.id}/collection`);
            }}
          >
            {t("breadcrumbs.collections")}
          </Box>
          <Typography
            sx={{ display: "flex", alignItems: "center", fontFamily: "Open Sans", fontSize: "14px", fontWeight: "600" }}
            color="text.primary"
          >
            {t("breadcrumbs.basicInfo")}
          </Typography>
        </Breadcrumbs>
        <Box
          sx={{
            mb: { mobile: "10px", md: "20px" },
            display: "flex",
            textAlign: "left",
          }}
        >
          <Typography variant={"h3"}>
            {t("brands.collection.create.basicInfo.title")}
          </Typography>
        </Box>
        <Paper
          sx={{
            width: "100%",
            backgroundColor: (theme) => theme.palette.bgTransparent.primary,
            px: { md: "50px", mobile: "10px" },
            py: { md: "50px", mobile: "20px" },
          }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "8px", mt: "0" }}>
                  <Typography variant={"h6"}>
                    {t("brands.collection.create.basicInfo.typeOfMedia.title")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: "10px",
                    mb: "0px",
                    display: "flex",
                    width: { mobile: "100%", md: "unset" },
                  }}
                >
                  <ToggleButtonGroup
                    sx={{ flexGrow: { md: "unset", mobile: 2 } }}
                    value={selectedMedia}
                    exclusive
                    onChange={handleMedia}
                    aria-label="select media"
                  >
                    <ToggleButton
                      sx={{
                        padding: { md: "0 10px", mobile: "0 6px" },
                        height: { md: "66px", mobile: "80px" },
                        border: "0px",
                        flexGrow: { mobile: 1, md: "unset" },
                        justifyContent: "center",
                      }}
                      value="photo"
                      aria-label="bold"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { md: "row", mobile: "column" },
                          alignItems: "center",
                          width: { md: "160px", mobile: "auto" },
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          component={"img"}
                          sx={{
                            width: "26px",
                            height: "26px",
                            m: { md: "0 8px 0 0", mobile: "6px 0px" },
                          }}
                          alt={"media upload"}
                          src={photoIcon}
                        />
                        <Typography
                          variant={"h9"}
                          sx={{
                            fontSize: {
                              md: "11px !important",
                              mobile: "10px !important",
                            },
                            display: "block",
                          }}
                        >
                          {t(
                            "brands.collection.create.basicInfo.typeOfMedia.photo"
                          )}
                        </Typography>
                      </Box>
                    </ToggleButton>
                    <ToggleButton
                      sx={{
                        padding: { md: "0 10px", mobile: "0 6px" },
                        ml: "4px !important",
                        height: { md: "66px", mobile: "80px" },
                        border: "0px",
                        flexGrow: { mobile: 1, md: "unset" },
                        justifyContent: "center",
                      }}
                      value="video"
                      aria-label="italic"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { md: "row", mobile: "column" },
                          alignItems: "center",
                          width: { md: "160px", mobile: "auto" },
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          component={"img"}
                          sx={{
                            width: "26px",
                            height: "26px",
                            m: { md: "0 8px 0 0", mobile: "6px 0px" },
                          }}
                          alt={"media upload"}
                          src={videoIcon}
                        />
                        <Typography
                          variant={"h9"}
                          sx={{
                            fontSize: {
                              md: "11px !important",
                              mobile: "10px !important",
                            },
                            display: "block",
                          }}
                        >
                          {t(
                            "brands.collection.create.basicInfo.typeOfMedia.animation"
                          )}
                        </Typography>
                      </Box>
                    </ToggleButton>
                    <ToggleButton
                      sx={{
                        padding: { md: "0 10px", mobile: "0 6px" },
                        ml: "4px !important",
                        height: { md: "66px", mobile: "80px" },
                        border: "0px",
                        flexGrow: { mobile: 1, md: "unset" },
                        justifyContent: "center",
                      }}
                      value="suprise-bag"
                      aria-label="italic"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { md: "row", mobile: "column" },
                          alignItems: "center",
                          width: { md: "160px", mobile: "auto" },
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          component={"img"}
                          sx={{
                            width: "26px",
                            height: "26px",
                            m: { md: "0 8px 0 0", mobile: "6px 0px" },
                          }}
                          alt={"media upload"}
                          src={surpriseBagIcon}
                        />
                        <Typography
                          variant={"h9"}
                          sx={{
                            fontSize: {
                              md: "11px !important",
                              mobile: "10px !important",
                            },
                            display: "block",
                          }}
                        >
                          {t(
                            "brands.collection.create.basicInfo.typeOfMedia.supriseBag"
                          )}
                        </Typography>
                      </Box>
                    </ToggleButton>
                    <ToggleButton
                      sx={{
                        padding: { md: "0 10px", mobile: "0 6px" },
                        ml: "4px !important",
                        height: { md: "66px", mobile: "80px" },
                        border: "0px",
                        flexGrow: { mobile: 1, md: "unset" },
                        justifyContent: "center",
                      }}
                      value="choose-nft"
                      aria-label="italic"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { md: "row", mobile: "column" },
                          alignItems: "center",
                          width: { md: "160px", mobile: "auto" },
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          component={"img"}
                          sx={{
                            width: "26px",
                            height: "26px",
                            m: { md: "0 8px 0 0", mobile: "6px 0px" },
                          }}
                          alt={"media upload"}
                          src={chooseNftIcon}
                        />
                        <Typography
                          variant={"h9"}
                          sx={{
                            fontSize: {
                              md: "11px !important",
                              mobile: "10px !important",
                            },
                            display: "block",
                          }}
                        >
                          {t(
                            "brands.collection.create.basicInfo.typeOfMedia.chooseYourNFT"
                          )}
                        </Typography>
                      </Box>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                {selectedMedia && (
                  <Box sx={{ mt: { mb: "40px", mobile: "30px" } }}>
                    {selectedMedia === "photo" && (
                      <Grid item md={12} mobile={12}>
                        <Box>
                          <Controller
                            name={"basic_info.picture_url"}
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <UI.Upload
                                aiButtonEnabled
                                noHeight
                                defaultValue={data?.basic_info?.picture_url}
                                {...field}
                                id={"profile"}
                                title={t(
                                  "brands.collection.create.basicInfo.uploadPhoto.title"
                                )}
                                subtitle={t(
                                  "brands.collection.create.basicInfo.uploadPhoto.subtitle"
                                )}
                                acceptFiles={
                                  "image/jpg, image/jpeg,image/gif,image/png"
                                }
                              />
                            )}
                          />
                          {errors.basic_info?.picture_url?.type ===
                            "required" && (
                              <Box sx={{ mt: "15px" }}>
                                <Alert variant="outlined" severity="error">
                                  {t(
                                    "brands.collection.create.basicInfo.uploadPhoto.error.required"
                                  )}
                                </Alert>
                              </Box>
                            )}
                        </Box>
                      </Grid>
                    )}
                    {selectedMedia === "video" && (
                      <>
                        <Grid item md={12} mobile={12}>
                          <Box>
                            <Controller
                              name={"basic_info.picture_url"}
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <UI.Upload
                                  noHeight
                                  defaultValue={data?.basic_info?.picture_url}
                                  {...field}
                                  id={"profile"}
                                  title={t(
                                    "brands.collection.create.basicInfo.uploadAnimation.title"
                                  )}
                                  subtitle={t(
                                    "brands.collection.create.basicInfo.uploadAnimation.subtitle"
                                  )}
                                  acceptFiles={
                                    "video/mp4, image/jpg, image/jpeg,image/gif,image/png"
                                  }
                                />
                              )}
                            />
                            {errors.basic_info?.picture_url?.type ===
                              "required" && (
                                <Box sx={{ mt: "15px" }}>
                                  <Alert variant="outlined" severity="error">
                                    {t(
                                      "brands.collection.create.basicInfo.uploadAnimation.error.required"
                                    )}
                                  </Alert>
                                </Box>
                              )}
                          </Box>
                        </Grid>
                        <Box
                          className={classes.inputOuter}
                          sx={{ mb: "0px !important" }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              mb: "10px",
                              mt: "23px !important",
                            }}
                          >
                            <Typography variant={"h6"}>
                              {t(
                                "brands.collection.create.basicInfo.animation.title"
                              )}
                            </Typography>
                            <UI.Tooltip>
                              {t(
                                "brands.collection.create.basicInfo.animation.tooltip"
                              )}
                            </UI.Tooltip>
                          </Box>
                          <Controller
                            name={"basic_info.nft_animation_url"}
                            control={control}
                            rules={{
                              required: true,
                              pattern: {
                                value:
                                  /^(http(s?):\/\/.)[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/g,
                                message: t(
                                  "brands.collection.create.basicInfo.animation.error.pattern"
                                ),
                              },
                            }}
                            render={({ field }) => (
                              <UI.TextField
                                {...field}
                                placeholder={t(
                                  "brands.collection.create.basicInfo.animation.placeholder"
                                )}
                              />
                            )}
                          />
                          {errors.basic_info?.nft_animation_url?.type ===
                            "pattern" && (
                              <Box sx={{ mt: "15px" }}>
                                <Alert variant="outlined" severity="error">
                                  {errors.basic_info?.nft_animation_url?.message}
                                </Alert>
                              </Box>
                            )}
                          {errors.basic_info?.nft_animation_url?.type ===
                            "required" && (
                              <Box sx={{ mt: "15px" }}>
                                <Alert variant="outlined" severity="error">
                                  {t(
                                    "brands.collection.create.basicInfo.animation.error.required"
                                  )}
                                </Alert>
                              </Box>
                            )}
                        </Box>
                      </>
                    )}
                    {selectedMedia === "suprise-bag" && (
                      <SupriseBag
                        getValues={getValues}
                        setTotalMintLimit={setTotalMintLimit}
                        data={data}
                        register={register}
                        control={control}
                        selectedRandomMint={selectedRandomMint}
                        errors={errors}
                        setValue={setValue}
                        watch={watch}
                      />
                    )}
                    {selectedMedia === "choose-nft" && (
                      <ChooseYourNFT
                        data={data}
                        register={register}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        watch={watch}
                      />
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h6"}>
                    {t("brands.collection.create.basicInfo.name.title")}
                  </Typography>
                  <UI.Tooltip>
                    {t("brands.collection.create.basicInfo.name.tooltip")}
                  </UI.Tooltip>
                </Box>
                <Controller
                  name={"basic_info.name"}
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <UI.TextField
                      aiButtonEnabled
                      {...field}
                      placeholder={t(
                        "brands.collection.create.basicInfo.name.placeholder"
                      )}
                    />
                  )}
                />
                {errors?.basic_info?.name?.type === "required" && (
                  <Box sx={{ mt: "15px" }}>
                    <Alert variant="outlined" severity="error">
                      {t(
                        "brands.collection.create.basicInfo.name.error.required"
                      )}
                    </Alert>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h6"}>
                    {t("brands.collection.create.basicInfo.symbol.title")}
                  </Typography>
                  <UI.Tooltip>
                    {t("brands.collection.create.basicInfo.symbol.tooltip")}
                  </UI.Tooltip>
                </Box>

                <Controller
                  name={"basic_info.symbol"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UI.TextField
                      {...field}
                      placeholder={t(
                        "brands.collection.create.basicInfo.symbol.placeholder"
                      )}
                    />
                  )}
                />
                {errors.basic_info?.symbol?.type === "required" && (
                  <Box sx={{ mt: "15px" }}>
                    <Alert variant="outlined" severity="error">
                      {t(
                        "brands.collection.create.basicInfo.symbol.error.required"
                      )}
                    </Alert>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h6"}>
                    {" "}
                    {t("brands.collection.create.basicInfo.description.title")}
                  </Typography>
                  <UI.Tooltip>
                    {t(
                      "brands.collection.create.basicInfo.description.tooltip"
                    )}
                  </UI.Tooltip>
                </Box>

                <Controller
                  name={"basic_info.description"}
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <UI.TextField
                      aiButtonEnabled
                      multiline
                      rows={3}
                      {...field}
                      placeholder={t(
                        "brands.collection.create.basicInfo.description.placeholder"
                      )}
                    />
                  )}
                />
                {errors.basic_info?.description?.type === "required" && (
                  <Box sx={{ mt: "15px" }}>
                    <Alert variant="outlined" severity="error">
                      {t(
                        "brands.collection.create.basicInfo.description.error.required"
                      )}
                    </Alert>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h6"}>
                    {t("brands.collection.create.basicInfo.numberOfNfts.title")}
                  </Typography>
                  <UI.Tooltip>
                    {t(
                      "brands.collection.create.basicInfo.numberOfNfts.tooltip"
                    )}
                  </UI.Tooltip>
                </Box>
                <Grid item md={12} mobile={12}>
                  <Box className={classes.inputOuter}>
                    <UI.Checkbox
                      disabled={selectedMedia === "suprise-bag"}
                      onClick={() => {
                        setValue("basic_info.is_supply_unlimited", true, {
                          shouldDirty: true,
                        });
                        setValue("basic_info.supply", "999999", {
                          shouldDirty: true,
                        });
                      }}
                      defaultChecked={true}
                      value={!!isSelectedSupplyTypeUnlimited === true}
                      nomargin={"true"}
                      label={
                        <Box
                          component={"span"}
                          sx={{
                            fontWeight: "regular",
                            fontFamily: "Open Sans",
                            fontSize: "16px",
                            color: (theme) => theme.palette.text.secondary,
                          }}
                        >
                          {t(
                            "brands.collection.create.basicInfo.numberOfNfts.option1"
                          )}
                        </Box>
                      }
                    />
                    <UI.Checkbox
                      disabled={selectedMedia === "suprise-bag"}
                      onClick={() => {
                        setValue("basic_info.is_supply_unlimited", false, {
                          shouldDirty: true,
                        });
                        setValue("basic_info.supply", null, {
                          shouldDirty: true,
                        });
                      }}
                      value={!!isSelectedSupplyTypeUnlimited === false}
                      nomargin={"true"}
                      label={
                        <Box
                          component={"span"}
                          sx={{
                            fontWeight: "regular",
                            fontFamily: "Open Sans",
                            fontSize: "16px",
                            color: (theme) => theme.palette.text.secondary,
                          }}
                        >
                          {t(
                            "brands.collection.create.basicInfo.numberOfNfts.option2"
                          )}
                        </Box>
                      }
                    />
                    {isSelectedSupplyTypeUnlimited === false && (
                      <Box sx={{ mt: "40px" }}>
                        <Controller
                          name={"basic_info.supply"}
                          control={control}
                          rules={{
                            required: true,
                            pattern: {
                              value: /^1*[1-9][0-9]*$/,
                              message: t(
                                "brands.collection.create.basicInfo.numberOfNfts.error.pattern"
                              ),
                            },
                          }}
                          render={({ field }) => (
                            <UI.NumberField
                              {...field}
                              disabled={selectedMedia === "suprise-bag"}
                              placeholder={"25"}
                            />
                          )}
                        />
                        {errors.basic_info?.supply?.type === "required" && (
                          <Box sx={{ mt: "15px" }}>
                            <Alert variant="outlined" severity="error">
                              {t(
                                "brands.collection.create.basicInfo.numberOfNfts.error.required"
                              )}
                            </Alert>

                            {errors.basic_info?.supply?.type === "pattern" && (
                              <Alert variant="outlined" severity="error">
                                {errors.supply.message}
                              </Alert>
                            )}
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <StoryMedia data={data} control={control} errors={errors} />
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "5px", mt: "15px" }}>
                  <Typography variant={"h6"}>
                    {t("brands.collection.create.basicInfo.enable.title")}
                  </Typography>
                  <UI.Tooltip>
                    {t("brands.collection.create.basicInfo.enable.tooltip")}
                  </UI.Tooltip>
                </Box>

                <UI.Checkbox
                  onClick={() => {
                    setValue("basic_info.is_scheduled_later", false, {
                      shouldDirty: true,
                    });
                    setValue("basic_info.scheduled_date_time", null, {
                      shouldDirty: true,
                    });
                  }}
                  defaultChecked={true}
                  value={selectedScheduleDisplay === false ? true : false}
                  nomargin={"true"}
                  label={
                    <Box
                      component={"span"}
                      sx={{
                        fontWeight: "regular",
                        fontFamily: "Open Sans",
                        fontSize: "16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                    >
                      {t("brands.collection.create.basicInfo.enable.option1")}
                    </Box>
                  }
                />
                <UI.Checkbox
                  onClick={() => {
                    setValue("basic_info.is_scheduled_later", true, {
                      shouldDirty: true,
                    });
                  }}
                  value={selectedScheduleDisplay === true}
                  nomargin={"true"}
                  label={
                    <Box
                      component={"span"}
                      sx={{
                        fontWeight: "regular",
                        fontFamily: "Open Sans",
                        fontSize: "16px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                    >
                      {t("brands.collection.create.basicInfo.enable.option2")}
                    </Box>
                  }
                />
                {selectedScheduleDisplay && (
                  <Box sx={{ mt: "40px" }}>
                    <Controller
                      name={"basic_info.scheduled_date_time"}
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => <UI.DateTime {...field} />}
                    />
                    {errors.basic_info?.scheduled_date_time?.type ===
                      "required" && (
                        <Box sx={{ mt: "15px" }}>
                          <Alert variant="outlined" severity="error">
                            {t(
                              "brands.collection.create.basicInfo.enable.error.required"
                            )}
                          </Alert>
                        </Box>
                      )}
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid item md={12} mobile={12}>
            <Box className={classes.inputOuter}>
              <Box sx={{ display: "flex", mb: "10px" }}>
                <Typography variant={"h6"}>
                  {t("brands.collection.create.basicInfo.visibility.title")}
                </Typography>
                <UI.Tooltip>
                  {t("brands.collection.create.basicInfo.visibility.tooltip")}
                </UI.Tooltip>
              </Box>
              <UI.Checkbox
                onClick={() => {
                  setValue("basic_info.visible", true, {
                    shouldDirty: true,
                  });
                }}
                defaultChecked={true}
                value={selectedVisible === true}
                nomargin={"true"}
                label={
                  <Box
                    component={"span"}
                    sx={{
                      fontWeight: "regular",
                      fontFamily: "Open Sans",
                      fontSize: "16px",
                      color: (theme) => theme.palette.text.secondary,
                    }}
                  >
                    {t("brands.collection.create.basicInfo.visibility.option1")}
                  </Box>
                }
              />
              <UI.Checkbox
                onClick={() => {
                  setValue("basic_info.visible", false, {
                    shouldDirty: true,
                  });
                }}
                value={selectedVisible === false}
                nomargin={"true"}
                label={
                  <Box
                    component={"span"}
                    sx={{
                      fontWeight: "regular",
                      fontFamily: "Open Sans",
                      fontSize: "16px",
                      color: (theme) => theme.palette.text.secondary,
                    }}
                  >
                    {t("brands.collection.create.basicInfo.visibility.option2")}
                  </Box>
                }
              />
            </Box>
          </Grid>
        </Paper>
      </Box>
      <Box
        sx={{
          width: "100%",
          mt: "20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "210px" }}>
            <UI.Button
              disabled={
                !!Object.keys(errors).length || !loggedInUser?.email_verified
              }
              title={t("buttons.saveAndContinue")}
              type={"primary"}
            />
            <UI.Button
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                const allValues = getValues();
                await onSubmit(allValues, "save");
              }}
              disabled={!loggedInUser?.email_verified}
              sx={{
                mt: "10px",
                filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
              }}
              title={t("buttons.saveForLater")}
              type={"tertiary"}
            />
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default Index;
