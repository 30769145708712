import React from "react";
import Box from "@mui/material/Box";
import { Link, useRouteMatch } from "react-router-dom";
import facebook from "../../static/facebook.png";
import x from "../../static/x.png";
import instagram from "../../static/instagram.png";
import telegram from "../../static/telegram.png";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  footerTitle: {
    color: theme.palette.text.primary,
    fontFamily: "Quantico",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "22px",
    lineHeight: "1.5",
    textTransform: "uppercase",
  },
  footerLink: {
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    opacity: "1",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "25px",
    "&:hover": {
      color: theme.palette.text.linkHover,
      transition: "all 0.3s ease-in-out",
    },
  },
  footerLinkMobile: {
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    opacity: "1",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "24px",
    "&:hover": {
      color: theme.palette.text.linkHover,
      transition: "all 0.3s ease-in-out",
    },
  },
  subscribeDescription: {
    marginTop: "20px",
    color: theme.palette.text.secondary,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "30px",
  },
  notchedOutline: {
    borderColor: "transparent !important",
  },
  verticalAlign: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

const Footer = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const showroomMatch = useRouteMatch("/brand/:brandId/showroom");
  const isShowRoomPage = showroomMatch !== null;
  return (
    <Box
      sx={{
        mt: "0px",
        width: "100%",
        display: isShowRoomPage === true ? "none" : "block",
      }}
    >
      <Box>
        <Box
          sx={{
            p: { md: "0px 100px", mobile: "0px" },
            paddingBottom: "0px",
            paddingTop: "0px",
          }}
        >
          <Box
            sx={{
              marginTop: "0px",
              p: { md: "40px 0px", mobile: "0px 0px 20px" },
              display: { mobile: "none", lg: "flex" },
              flexDirection: { mobile: "column", md: "row" },
              justifyContent: { mobile: "center", md: "space-between" },
              alignItems: "center",
            }}
          >
            <Typography
              variant={"subtitle4"}
              sx={{
                display: "flex",
                alignItems: "center",
                mb: { mobile: "10px", md: "0px" },
                fontWeight: "500",
              }}
            >
              {`© 2024 HAZEL LABS d. o. o | ${t("footer.version")} ${process.env.REACT_APP_VERSION
                }`}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: { mobile: "20px", md: "0px" },
              }}
            >
              <Link
                to={{
                  pathname: "https://about.qvrse.io/",
                }}
                target="_blank"
                className={classes.footerLink}
              >
                {t("footer.website")}
              </Link>
              <Box sx={{ ml: "40px" }}>
                <Link
                  to={{
                    pathname: "https://blog.qvrse.io/",
                  }}
                  target="_blank"
                  className={classes.footerLink}
                >
                  {t("footer.blog")}
                </Link>
              </Box>
              <Box sx={{ ml: "40px" }}>
                <Link
                  to={{
                    pathname: "https://qvrse-1.gitbook.io/qvrse-docs/",
                  }}
                  target="_blank"
                  className={classes.footerLink}
                >
                  {t("footer.docs")}
                </Link>
              </Box>
              <Box sx={{ ml: "40px" }}>
                <Link
                  to={{
                    pathname: "https://files.qvrse.io/Terms_and_Conditions.pdf",
                  }}
                  target="_blank"
                  className={classes.footerLink}
                >
                  {t("footer.terms")}
                </Link>
              </Box>
              <Box sx={{ ml: "40px", mr: "75px" }}>
                <Link
                  to={{ pathname: "https://files.qvrse.io/Privacy_Policy.pdf" }}
                  target="_blank"
                  className={classes.footerLink}
                >
                  {t("footer.privacy")}
                </Link>
              </Box>
            </Box>
            <Box sx={{ display: "flex", mb: { mobile: "10px", md: "0px" } }}>
              <Box
                className={classes.verticalAlign}
                sx={{
                  transition: "all 0.5s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              >
                <Link
                  to={{ pathname: "https://t.me/+BtPqSN3sk9YwYzlk" }}
                  target="_blank"
                  className={classes.footerLink}
                >
                  <img
                    width={"30px"}
                    height={"30px"}
                    src={telegram}
                    alt={"telegram"}
                  />
                </Link>
              </Box>
              <Box
                className={classes.verticalAlign}
                sx={{
                  ml: "10px",
                  transition: "all 0.5s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              >
                <Link
                  to={{ pathname: "https://www.instagram.com/qvrse.io/" }}
                  target="_blank"
                  className={classes.footerLink}
                >
                  <img
                    width={"30px"}
                    height={"30px"}
                    src={instagram}
                    alt={"instagram"}
                  />
                </Link>
              </Box>
              <Box
                className={classes.verticalAlign}
                sx={{
                  ml: "10px",
                  transition: "all 0.5s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              >
                <Link
                  to={{ pathname: "https://x.com/QvrseDAO" }}
                  target="_blank"
                  className={classes.footerLink}
                >
                  <img
                    width={"30px"}
                    height={"30px"}
                    // src={twitter}
                    src={x}
                    // alt={"twitter"}
                    alt={"x"}
                  />
                </Link>
              </Box>
              <Box
                className={classes.verticalAlign}
                sx={{
                  ml: "10px",
                  transition: "all 0.5s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              >
                <Link
                  to={{ pathname: "https://www.facebook.com/qvrse.io" }}
                  target="_blank"
                  className={classes.footerLink}
                >
                  <img
                    width={"30px"}
                    height={"30px"}
                    src={facebook}
                    alt={"facebook"}
                  />
                </Link>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: "0px",
              p: { md: "40px 0px", mobile: "4px 0px 20px" },
              display: { mobile: "flex", lg: "none" },
              flexDirection: { mobile: "column", md: "row" },
              justifyContent: { mobile: "center", md: "space-between" },
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: { mobile: "5px", md: "0px" },
              }}
            >
              <Link
                to={{
                  pathname: "https://about.qvrse.io/",
                }}
                target="_blank"
                className={classes.footerLinkMobile}
              >
                {t("footer.website")}
              </Link>
              <Box sx={{ ml: "20px" }}>
                <Link
                  to={{
                    pathname: "https://blog.qvrse.io/",
                  }}
                  target="_blank"
                  className={classes.footerLinkMobile}
                >
                  {t("footer.blog")}
                </Link>
              </Box>
              <Box sx={{ ml: "20px" }}>
                <Link
                  to={{
                    pathname: "https://qvrse-1.gitbook.io/qvrse-docs/",
                  }}
                  target="_blank"
                  className={classes.footerLink}
                >
                  {t("footer.docs")}
                </Link>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: { mobile: "15px", md: "0px" },
              }}
            >
              <Box>
                <Link
                  to={{
                    pathname: "https://files.qvrse.io/Terms_and_Conditions.pdf",
                  }}
                  target="_blank"
                  className={classes.footerLinkMobile}
                >
                  {t("footer.terms")}
                </Link>
              </Box>
              <Box sx={{ ml: "20px" }}>
                <Link
                  to={{ pathname: "https://files.qvrse.io/Privacy_Policy.pdf" }}
                  target="_blank"
                  className={classes.footerLinkMobile}
                >
                  {t("footer.privacy")}
                </Link>
              </Box>
            </Box>
            <Typography
              variant={"subtitle4"}
              sx={{
                display: "flex",
                alignItems: "center",
                mb: { mobile: "10px", md: "0px" },
                fontWeight: "500",
              }}
            >
              {`© 2024 HAZEL LABS d. o. o. | ${process.env.REACT_APP_VERSION}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
