import React from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import UI from "../../../../../@components/UI";
import gameIcon from "../../../../../static/game-icon.png";

const Game = ({ disableClick, item }) => {
  const history = useHistory();
  return (
    <Box
      key={item.id}
      onClick={() => {
        if (disableClick) {
          return;
        }
        history.push({
          pathname: `/brand/42/collection/529`,
          state: { scrollToTop: true },
        });
      }}
      sx={{
        width: { md: "155px", mobile: "102px" },
        height: { md: "232px", mobile: "153px" },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "155px",
          width: "100%",
          overflow: "hidden",
          borderRadius: "0px",
        }}
      >
        <UI.ImageWithSkeleton
          sx={{
            width: { md: "110px", mobile: "70px" },
            height: { md: "110px", mobile: "70px" },
            transition: "all 0.3s ease-in-out",
          }}
          src={gameIcon}
        />
      </Box>
    </Box>
  );
};

export default Game;
