import React from "react";
import AddNewCard from "./AddNewCard";
import { Box } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import Collection from "../../cards/collection";
import Discount from "../../cards/discount";
import Code from "../../cards/code";
import Spatial from "../../cards/spatial";
import Video from "../../cards/video";
import Animation from "../../cards/animation";
import Text from "../../cards/text";
import Image from "../../cards/image";
import Game from "../../cards/Game";
import AllCollection from "../../cards/allCollection";
const List = ({ cards, onRemoveCard }) => {
  return (
    <Grid
      container
      columnSpacing={{ mobile: 0, md: 3 }}
      rowSpacing={{ mobile: 1, md: 3 }}
      justifyContent="stretch"
    >
      {cards?.map((item) => {
        return (
          <Grid
            item
            mobile={4}
            md={3}
            lg={3}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                width: { lg: "300px", md: "230px", mobile: "110px" },
                height: { lg: "300px", md: "230px", mobile: "110px" },
              }}
            >
              {item.album_space_type === "Collection" &&
                item?.collections?.length <= 1 && (
                  <Box sx={{ height: "100%", width: "100%" }}>
                    <Collection.Overlay.Action
                      onDelete={() => {
                        onRemoveCard(item.id);
                      }}
                      element={<Collection.EditCard item={item} />}
                    />
                  </Box>
                )}
              {item.album_space_type === "Collection" &&
                item?.collections?.length > 1 && (
                  <Box sx={{ height: "100%", width: "100%" }}>
                    <AllCollection.Overlay.Action
                      onDelete={() => {
                        onRemoveCard(item.id);
                      }}
                      element={<AllCollection.EditCard item={item} />}
                    />
                  </Box>
                )}
              {item.album_space_type === "Discount" && (
                <Discount.Overlay.Action
                  onDelete={() => {
                    onRemoveCard(item.id);
                  }}
                  element={
                    <Discount.BackSide.Action item={item} isEditing={true} />
                  }
                />
              )}
              {item.album_space_type === "Spatial" && (
                <Discount.Overlay.Action
                  onDelete={() => {
                    onRemoveCard(item.id);
                  }}
                  element={
                    <Spatial.BackSide.Action item={item} isEditing={true} />
                  }
                />
              )}
              {item.album_space_type === "Code" && (
                <Code.Overlay.Action
                  onDelete={() => {
                    onRemoveCard(item.id);
                  }}
                  element={
                    <Code.BackSide.Action item={item} isEditing={true} />
                  }
                />
              )}
              {item.album_space_type === "Video" && (
                <Video.Overlay.Action
                  onDelete={() => {
                    onRemoveCard(item.id);
                  }}
                  element={
                    <Video.BackSide.Action item={item} isEditing={true} />
                  }
                />
              )}
              {item.album_space_type === "Animation" && (
                <Animation.Overlay.Action
                  onDelete={() => {
                    onRemoveCard(item.id);
                  }}
                  element={
                    <Animation.BackSide.Action item={item} isEditing={true} />
                  }
                />
              )}
              {item.album_space_type === "Text" && (
                <Box sx={{ height: "100%", width: "100%" }}>
                  <Text.Overlay.Action
                    onDelete={() => {
                      onRemoveCard(item.id);
                    }}
                    element={
                      <Text.BackSide.Action item={item} isEditing={true} />
                    }
                  />
                </Box>
              )}
              {item.album_space_type === "Image" && (
                <Image.Overlay.Action
                  onDelete={() => {
                    onRemoveCard(item.id);
                  }}
                  element={
                    <Image.BackSide.Action item={item} isEditing={true} />
                  }
                />
              )}
              {item.album_space_type === "game" && <Game item={{ id: 0 }} />}
            </Box>
          </Grid>
        );
      })}
      <Grid item mobile={4} md={3} lg={3} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            width: { lg: "300px", md: "230px", mobile: "110px" },
            height: { lg: "300px", md: "230px", mobile: "110px" },
          }}
        >
          <AddNewCard item={{ id: 1 }} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default List;
