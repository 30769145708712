import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../@components/UI";
import CardSkeletonList from "./CardSkeletonList";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import { useHistory } from "react-router-dom";

const CardList = ({ items, busy }) => {
  return (
    <Grid container spacing={2} columns={20}>
      {busy && <CardSkeletonList />}
      {items?.length > 0 &&
        items?.map((item, index) => (
          <Item item={item} key={"collection_" + index} />
        ))}
    </Grid>
  );
};

const Item = ({ item }) => {
  return (
    <Grid
      item
      mobile={20}
      tablet={6.66}
      md={6.66}
      lg={5}
      xxl={4}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <CollectionCard item={item} />
    </Grid>
  );
};

const CollectionCard = ({ item }) => {
  const { t } = useTranslation();
  const history = useHistory();

  item?.nft_file_type === "" && (item.nft_file_type = "image/png");

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push({
      pathname: `/brand/${item?.dao_id}/collection/${item?.id}`,
      state: { scrollToTop: true },
    });
  };

  return (
    <Card
      onClick={handleClick}
      sx={{
        width: "295.333px",
        height: "303.383px",
        margin: "auto",
        cursor: "pointer",
        background: (theme) => theme.palette.bgTransparent.primary,
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          background: (theme) => theme.palette.bgTransparent.secondary,
        },
      }}
    >
      {item?.nft_file_type.startsWith("image/") && (
        <CardMedia
          component="img"
          alt={item.name}
          height="180"
          image={item.nft_image_url}
          sx={{ objectFit: "cover" }}
        />
      )}
      {item?.nft_file_type.startsWith("video/") && (
        <CardMedia
          component="video"
          alt={item.name}
          height="180"
          sx={{ objectFit: "cover" }}
          src={item.nft_image_url}
          playsInline
          autoPlay
          muted
          loop
        />
      )}
      <CardContent
        sx={{
          p: "13px 15px 18px !important",
        }}
      >
        <Typography variant="subtitle4">{item.dao?.name}</Typography>
        <Typography
          gutterBottom
          variant="h7"
          component="div"
          sx={{ lineHeight: "1.3" }}
        >
          <UI.OverflowTip>{item.name}</UI.OverflowTip>
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {item?.total_price_with_tax !== "0.00" && (
            <Box sx={{ display: "block" }}>
              <Typography variant="subtitle4" sx={{ fontSize: "11px" }}>
                {t("commons.price")}
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ width: "18px", height: "18px", mr: "7px" }}>
                    <UI.PaymentIcon currency={item?.currency} />
                  </Box>
                  <Typography variant="h8" sx={{ lineHeight: "1.2" }}>
                    {`${item?.total_price_with_tax}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          {item?.total_price_with_tax === "0.00" && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant={"h10"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0px 14px",
                  mt: "15px",
                  height: "22px",
                  width: "fit-content",
                  borderRadius: "100px",
                  backgroundColor: (theme) => theme.palette.tag.red,
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                {t("commons.free")}
              </Typography>
            </Box>
          )}

          {item.nft_amount_limit < 999999 && (
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "block", textAlign: "end" }}>
                <Typography variant={"subtitle4"} sx={{ fontSize: "11px" }}>
                  {t("commons.claimed")}
                </Typography>
                <Box sx={{ display: "flex", textAlign: "end" }}>
                  <Typography variant={"h8"} sx={{ lineHeight: "1.2" }}>
                    {`${item.nft_amount_minted} / ${item.nft_amount_limit}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardList;
