import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import UI from "../@components/UI";
import Grid from "@mui/material/Grid";
import SmallSkeletonList from "./SmallSkeletonList";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Item = ({ item }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  return (
    <Grid item mobile={12} tablet={6} md={6} lg={6}>
      <Paper
        sx={{
          backgroundColor: (theme) => theme.palette.bgTransparent.primary,
        }}
      >
        <Box
          onClick={() =>
            history.push({
              pathname: `/brand/${item?.dao_id}/collection/${item?.id}`,
              state: { scrollToTop: true },
            })
          }
          sx={{
            cursor: "pointer",
            border: "1px solid transparent",
            borderRadius: (theme) => theme.borderRadius.primary,
            transition: "all 0.2s ease-in-out",
            "&:hover": {
              boxShadow: "#944dff 0px 0px 10px 0px",
              border: "1px solid #944DFF",
              backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
            },
            display: "flex",
            justifyContent: "space-between",
            padding: { md: "17px 20px", mobile: "15px 10px" },
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box sx={{ mr: "12px" }}>
              {item.nft_file_type?.startsWith("video") ? (
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    overflow: "hidden",
                    borderRadius: "5px !important",
                  }}
                >
                  <video
                    playsInline
                    style={{
                      width: "100%",
                      height: "100%",
                      textAlign: "center",
                      objectFit: "cover",
                      color: "transparent",
                      textIndent: "10000px",
                    }}
                    autoPlay
                    loop
                    muted
                  >
                    <source src={item.nft_image_url} />
                  </video>
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    overflow: "hidden",
                    borderRadius: "5px !important",
                  }}
                >
                  <Box
                    component={"img"}
                    src={item.nft_image_url}
                    alt={"avatar"}
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      textIndent: "10000px",
                    }}
                  />
                </Box>
              )}
            </Box>
            <Box>
              <Typography
                variant={"h4"}
                sx={{
                  fontSize: "14px !important",
                  maxWidth: { mobile: "212px", md: "290px", lg: "540px" },
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {item?.name_slo !== null &&
                  item?.name_slo !== "" &&
                  i18n.language === "sl"
                  ? item?.name_slo
                  : item?.name || ""}
              </Typography>
              <Typography
                variant={"subtitle4"}
                sx={{
                  maxWidth: { mobile: "212px", md: "290px", lg: "540px" },
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  display: "flex",
                }}
              >
                {item?.dao?.name}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "none" }}>
            <Box
              sx={{
                mr: "16px",
                width: "85px",
                display: { md: "flex", mobile: "none" },
                alignItems: "center",
                position: "relative",
              }}
            >
              {item?.free_nft_gas_fee_payed_by_system === false && (
                <Box sx={{ width: "20px", height: "20px" }}>
                  <UI.PaymentIcon currency={item.currency || "MATIC"} />
                </Box>
              )}

              {item.total_price_with_tax !== "0.00" && (
                <Typography
                  variant={"h8"}
                  sx={{
                    ml: "20px",
                    right: "0px",
                    position: "absolute",
                  }}
                >
                  {parseFloat(item.total_price_with_tax).toFixed(2)}
                </Typography>
              )}
              {item.total_price_with_tax === "0.00" && (
                <Typography
                  variant={"h8"}
                  sx={{
                    ml: "20px",
                    right: "0px",
                    position: "absolute",
                    textAlign: "end",
                  }}
                >
                  {t("commons.free")}
                </Typography>
              )}
            </Box>
            {item.nft_amount_limit < 999999 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  maxWidth: "60px",
                  width: "60px",
                }}
              >
                <Typography
                  variant={"h8"}
                  sx={{
                    textAlign: "end",
                  }}
                >
                  <UI.OverflowTip>
                    {`${item.nft_amount_minted}/${item.nft_amount_limit}`}
                  </UI.OverflowTip>
                </Typography>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box sx={{ display: "flex" }}>
              {item?.free_nft_gas_fee_payed_by_system === false && (
                <Box sx={{ width: "14px", height: "14px" }}>
                  <UI.PaymentIcon currency={item.currency || "MATIC"} />
                </Box>
              )}
              {item.total_price_with_tax !== "0.00" && (
                <Typography
                  variant={"h8"}
                  sx={{
                    ml: "6px",
                    textAlign: "end",
                  }}
                >
                  {parseFloat(item.total_price_with_tax).toFixed(2)}
                </Typography>
              )}

              {item.total_price_with_tax === "0.00" && (
                <Typography
                  variant={"h8"}
                  sx={{
                    ml: "6px",
                    textAlign: "end",
                  }}
                >
                  {t("commons.free")}
                </Typography>
              )}
            </Box>
            {item.nft_amount_limit < 999999 && (
              <Typography
                variant={"h9"}
                sx={{
                  color: (theme) => theme.palette.text.tertiary,
                  textAlign: "end",
                }}
              >
                <UI.OverflowTip>
                  {`${item.nft_amount_minted}/${item.nft_amount_limit}`}
                </UI.OverflowTip>
              </Typography>
            )}
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

const SmallList = ({ limit = { from: 0, to: -1 }, items, busy }) => {
  return (
    <Grid container spacing={2}>
      {busy && <SmallSkeletonList />}
      {!busy &&
        items &&
        items.length > 0 &&
        items
          .slice(limit.from, limit.to === -1 ? items.length : limit.to)
          .map((item) => <Item item={item} key={item.id} />)}
      {!busy && items && items.length === 0 && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <UI.NoResults text={"No collections found :("} />
        </Box>
      )}
    </Grid>
  );
};

export default SmallList;
