import React, { useState } from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import { useBalance } from "wagmi";
import truncateEthAddress from "truncate-eth-address";
import UI from "../../@components/UI";
import tokenArtifact from "../../abis/contracts/token/QvrseToken.sol/QvrseToken.json";
import { ethers } from "ethers";
import Grid from "@mui/material/Grid";
import useService from "../../daos/@id/collection/useService";
import Alert from "@mui/material/Alert";
import CustomHooks from "../../@components/hooks";
import { useTranslation } from "react-i18next";
import QvrsButton from "../../@components/UI/Button";
import qvrseLogo from "../../static/qvrse-logo.png";
import maticLogo from "../../static/matic-logo.png";
import CreateSmartAccountDialog from "../dialogs/CreateSmartAccountDialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import ExportWalletDialog from "./ExportWalletDialog";
import AddNewPaskey from "./AddNewPasskey";


const Index = ({ user, refetchUser }) => {
  const { t } = useTranslation();
  const service = useService();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const provider = CustomHooks.useEthersProvider();
  const [otherTokens, setOtherTokens] = React.useState([]);
  const [nativeTokenBalance, setNativeTokenBalance] = React.useState(0);
  const [qvrseBalance, setQvrseBalance] = React.useState(0);
  const [showSmartAccountDialog, setShowSmartAccountDialog] =
    React.useState(false);
  const [triggerExportWallet, setTriggerExportWallet] = useState(false);
  const { data } = useBalance({ address: user?.smart_address });
  const { data: paymentOptions } = CustomHooks.useFetch(
    ["payment_options"],
    service.paymentOptions,
    {
      select: (response) => {
        return response.filter((item) => item.type !== "NATIVE");
      },
    }
  );

  const fetchTokenBalance = (item) => {
    return new Promise((resolve, reject) => {
      if (!item?.address || !ethers.isAddress(item.address)) {
        console.error("Invalid token address");
        resolve(ethers.formatUnits("0", 18));
        return;
      }

      if (!user?.smart_address || !ethers.isAddress(user.smart_address)) {
        console.error("Invalid user smart address");
        resolve(ethers.formatUnits("0", 18));
        return;
      }

      const token = new ethers.Contract(
        item.address,
        tokenArtifact.abi,
        provider
      );

      token
        .balanceOf(user.smart_address)
        .then((tokenBalance) => {
          if (!tokenBalance) {
            resolve("0");
          } else {
            resolve(ethers.formatUnits(tokenBalance, item.decimals));
          }
        })
        .catch((error) => {
          console.error("Error fetching balance: ", error);
          resolve("0");
        });
    });
  };

  const fetchBalances = async () => {
    const tokens = await Promise.all(
      paymentOptions.map((item) => {
        return fetchTokenBalance(item)
          .then((balance) => {
            return { ...item, balance };
          })
          .catch((error) => {
            console.error("Error in fetchTokenBalance: " + error);
          });
      })
    );

    setOtherTokens(tokens);
  };

  React.useEffect(() => {
    if (paymentOptions) {
      fetchBalances();
    }
    //eslint-disable-next-line
  }, [paymentOptions]);

  React.useEffect(() => {
    if (data && data?.value) {
      setNativeTokenBalance(ethers.formatEther(data?.value));
    }
  }, [data]);

  React.useEffect(() => {
    const fetchData = async () => {
      const token = new ethers.Contract(
        user?.qvrs_token_address,
        tokenArtifact.abi,
        provider
      );

      const tokenBalance = await token.balanceOf(user?.smart_address || 0);

      setQvrseBalance(ethers.formatEther(tokenBalance));
    };
    provider && user && user?.smart_address !== null && fetchData();
    //eslint-disable-next-line
  }, [provider, user]);


  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "column", mobile: "column" },
          justifyContent: "center",
          padding: { lg: "10px 70px 20px", md: "10px 20px 20px 90px", tablet: "0 0 20px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { mobile: "column", md: "row" },
          }}
        >
          <Typography variant={"h3"}>{t("profile.finance.title")}</Typography>
        </Box>
        <Box sx={{ padding: { md: "30px 0px", mobile: "20px 0px" } }}>
          <Box>
            <Grid container>
              {!user?.email_verified && (
                <Grid
                  item
                  mobile={12}
                  md={12}
                  sx={{
                    mt: { mobile: "15px", md: "15px" },
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      display: { md: "none", mobile: "block" },
                    }}
                  >
                    <Alert
                      variant="outlined"
                      severity="info"
                      sx={{
                        color: "#4DB8FF",
                        fontWeight: "bold",
                        padding: "20px",
                        height: "auto",
                        mb: "15px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        {t("profile.finance.alert")}
                      </Box>
                    </Alert>
                  </Box>
                </Grid>
              )}
              <Grid
                item
                mobile={12}
                md={12}
                sx={{
                  mt: { mobile: "0px", md: "0px" },
                }}
              >
                <Typography
                  sx={{
                    mb: "8px",
                  }}
                  variant={"h6"}
                >
                  {t("profile.finance.walletAddress")}
                </Typography>
                <Paper
                  sx={{
                    cursor: "default",
                    border: "1px solid transparent",
                    borderRadius: (theme) => theme.borderRadius.primary,
                    backgroundColor: (theme) =>
                      theme.palette.bgTransparent.primary,
                    transition: "all 0.2s ease-in-out",
                    "&:hover": {
                      boxShadow: "#944dff 0px 0px 10px 0px",
                      border: "1px solid #944DFF",
                      backgroundColor: (theme) =>
                        theme.palette.bgTransparent.secondary,
                    },
                  }}
                >
                  <Box
                    sx={{
                      height: "60px",
                      padding: { md: "10px 30px", mobile: "10px 5px" },
                      display: "flex",
                      alignItems: "center !important",
                      position: "relative",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      {!isMobile && (
                        <Box
                          sx={{
                            mr: "10px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Avatar src={user?.avatar_url} alt={"avatar"} />
                        </Box>
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant={"subtitle2"}>
                          {user?.smart_address ? (
                            <UI.CopyToClipboard
                              label={truncateEthAddress(
                                user?.smart_address || ""
                              )}
                              value={user?.smart_address}
                              showBorder
                            />
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                height: "100%",
                                mr: "10px",
                              }}
                            >
                              <QvrsButton
                                onClick={() => {
                                  setShowSmartAccountDialog(Math.random());
                                }}
                                sx={{
                                  height: "42px",
                                  fontSize: "14px",
                                }}
                                title={t("commons.secureAccount")}
                                type={"secondary"}
                              />
                            </Box>
                          )}
                        </Typography>
                      </Box>
                    </Box>
                    {user?.smart_address !== null && (
                      <UI.SmartAccountActionButton
                        title={isMobile ? "Export" : "Export Wallet"}
                        isLoading={false}
                        onClick={() => {
                          setTriggerExportWallet(Math.random());
                        }}
                      />
                    )}
                  </Box>
                </Paper>

                {user?.smart_address === "" && (
                  <Box
                    sx={{
                      position: "relative",
                      display: { md: "block", mobile: "block" },
                    }}
                  >
                    <Alert
                      variant="outlined"
                      severity="info"
                      sx={{
                        color: "#4DB8FF",
                        height: "50px",
                        width: "100%",
                        fontWeight: "bold",
                        mt: "15px",
                      }}
                    >
                      <Box
                        sx={{
                          display: { md: "flex", mobile: "block" },
                          width: "100%",
                        }}
                      >
                        {t("profile.finance.alert2")}
                      </Box>
                    </Alert>
                  </Box>
                )}
              </Grid>
              <Grid item mobile={12} md={12}>
                <Box sx={{ display: "flex", mt: "20px" }}>
                  <Box
                    sx={{
                      position: "relative",
                      display: { md: "block", mobile: "block" },
                    }}
                  >
                    <Alert
                      variant="outlined"
                      severity="info"
                      sx={{
                        width: { md: "100%", mobile: "100%" },
                      }}
                    >
                      <Box
                        sx={{
                          display: { md: "flex", mobile: "block" },
                        }}
                      >
                        {t("profile.finance.addNewPasskey")}
                        <AddNewPaskey />
                      </Box>
                    </Alert>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                mobile={12}
                md={12}
                sx={{
                  mt: { mobile: "30px", md: "40px" },
                }}
              >
                <Typography
                  sx={{
                    mb: "8px",
                  }}
                  variant={"h6"}
                >
                  {t("profile.finance.cryptoCurrencies")}
                </Typography>
                {qvrseBalance !== null && (
                  <TokenItem
                    sx={{ mb: "15px" }}
                    name={"QVRS"}
                    icon={qvrseLogo}
                    amount={qvrseBalance}
                  />
                )}

                {nativeTokenBalance !== null && (
                  <TokenItem
                    sx={{ mb: "15px" }}
                    name={"MATIC"}
                    icon={maticLogo}
                    amount={nativeTokenBalance || 0}
                  />
                )}

                {otherTokens.map((item) => (
                  <TokenItem
                    key={item.address}
                    item={item}
                    icon={item.icon}
                    name={item.name}
                    sx={{ mb: "15px" }}
                    amount={item.balance || 0}
                  />
                ))}
              </Grid>
              <Grid item mobile={12} md={12}>
                <Box sx={{ display: "flex" }}>
                  <Box
                    sx={{
                      position: "relative",
                      display: { md: "block", mobile: "block" },
                    }}
                  >
                    <Alert
                      variant="outlined"
                      severity="info"
                      sx={{
                        width: { md: "350px", mobile: "108%" },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <>
                          {t("profile.finance.getTokens")}
                          <Link
                            sx={{
                              color: (theme) => theme.palette.text.linkBlue,
                              textDecorationColor: (theme) =>
                                theme.palette.text.linkBlue,
                              ml: "5px",
                              "&:hover": {
                                color: (theme) =>
                                  theme.palette.text.linkBlueHover,
                                textDecorationColor: (theme) =>
                                  theme.palette.text.linkBlueHover,
                                transition: "all 0.3s ease-in-out",
                              },
                            }}
                            href={"https://app.uniswap.org/"}
                            target="_blank"
                          >
                            {" "}
                            Uniswap
                          </Link>
                        </>
                      </Box>
                    </Alert>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      {/*<UI.ConnectToSmartAccount*/}
      {/*  open={showConnectSmartAccountDialog}*/}
      {/*  onClose={() => setShowConnectSmartAccountDialog(false)}*/}
      {/*  onSuccess={() => {*/}
      {/*    setShowConnectSmartAccountDialog(false);*/}
      {/*    console.log("success");*/}
      {/*  }}*/}
      {/*/>*/}
      <CreateSmartAccountDialog
        toggleOpen={showSmartAccountDialog}
        onSuccess={() => refetchUser()}
      />
      <ExportWalletDialog toggleOpen={triggerExportWallet} />
    </>
  );
};

const TokenItem = (props) => {
  const { name, icon, amount } = props;
  return (
    <Box {...props}>
      <Paper
        sx={{
          cursor: "default",
          border: "1px solid transparent",
          borderRadius: (theme) => theme.borderRadius.primary,
          backgroundColor: (theme) => theme.palette.bgTransparent.primary,
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            boxShadow: "#944dff 0px 0px 10px 0px",
            border: "1px solid #944DFF",
            backgroundColor: (theme) => theme.palette.bgTransparent.secondary,
          },
        }}
      >
        <Box
          sx={{
            height: "60px",
            padding: { md: "20px 30px", mobile: "20px 15px" },
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                display: { md: "flex", mobile: "none" },
              }}
              variant={"h6"}
            >
              {name}
            </Typography>
            <Typography
              sx={{
                display: { md: "none", mobile: "flex" },
              }}
              variant={"h8"}
            >
              {name}
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                width: "25px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <img src={icon} alt={"icon"} />
            </Box>
            <Box
              sx={{
                ml: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant={"h7"}>
                {parseFloat(amount).toFixed(2)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default Index;
