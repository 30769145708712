import React from "react";
import Box from "@mui/material/Box";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Pagination } from "swiper/modules";

import "./styles.css";
import Dialog from "@mui/material/Dialog";
import playButtonImg from "../../../static/play.png";

const ImageItem = ({ item, onClick }) => {
  return (
    <Box
      onClick={() => onClick(item)}
      sx={{
        cursor: "pointer",
        backgroundColor: "transparent",
        textAlign: "center",
        height: "400px",
        position: "relative",
        width: "100%"
      }}
    >
      <Box
        component={"img"}
        sx={{ width: "100%", height: "340px !important", borderRadius: "0 0 10px 10px" }}
        src={item.link}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1,
        }}
      ></div>
    </Box>
  );
};

const VideoItem = ({ item, onClick }) => {
  return (
    <Box
      onClick={() => onClick(item)}
      sx={{
        cursor: "pointer",
        backgroundColor: "transparent",
        textAlign: "center",
        mb: "-7.5px",
      }}
    >
      <Box sx={{ position: "relative", maxHeight: "500px", height: "320px" }}>
        <video
          onContextMenu={(e) => e.preventDefault()}
          width="100%"
          height={"100%"}
          playsInline
          autoplay="autoplay"
          loop
          muted
        >
          <source src={item.link} />
        </video>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1,
            pointerEvents: "none",
          }}
        ></div>
      </Box>
      <Box
        sx={{
          margin: "0",
          position: "absolute",
          top: "50%",
          left: "50%",
          filter: "drop-shadow(4px 14px 12px black) brightness(0.8)",
          transform: "translate(-50%, -50%)",
          "&:hover": {
            filter: "brightness(2)",
          },
        }}
      >
        <Box
          component={"img"}
          sx={{ width: "100px !important" }}
          src={playButtonImg}
          alt={"play-video"}
        />
      </Box>
    </Box>
  );
};

const PreviewSwiper = ({ items }) => {
  const [previewFile, setPreviewFile] = React.useState(false);

  return (
    <Box className={"previewSwiper"}>
      {items?.length && (
        <Swiper
          pagination={true}
          modules={[Pagination]}
          grabCursor={true}
          className="mySwiper"
        >
          {items.map((item) => (
            <Box key={item.id}>
              <SwiperSlide>
                {(item.media_type.toLowerCase().startsWith("image") ||
                  (item.media_type.toLowerCase().startsWith("") &&
                    !item.media_type.startsWith("video")) ||
                  item.file_type.toLowerCase().startsWith("gif")) && (
                  <ImageItem
                    item={item}
                    onClick={(file) => setPreviewFile(file)}
                  />
                )}
                {item.media_type.startsWith("video") && (
                  <VideoItem
                    onClick={(file) => setPreviewFile(file)}
                    item={item}
                  />
                )}
              </SwiperSlide>
            </Box>
          ))}
        </Swiper>
      )}
      <Dialog
        fullWidth
        onClose={() => setPreviewFile(null)}
        open={previewFile}
        sx={{
          "& .MuiPaper-root": {
            background: "transparent",
            boxShadow: "none",
            maxWidth: "max-content",
          },
        }}
        PaperProps={{
          maxWidth: "1200px",
        }}
      >
        {previewFile &&
          (previewFile.media_type === "image" ||
            previewFile.media_type === "" ||
            previewFile.file_type === "gif") && (
            <Box sx={{ position: "relative" }}>
              <Box
                component={"img"}
                sx={{
                  maxHeight: { md: "600px", mobile: "auto" },
                  width: { md: "auto", mobile: "100%" },
                  margin: "0 auto",
                  borderRadius: "10px"
                }}
                src={previewFile.link}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: 1,
                }}
              ></div>
            </Box>
          )}
        {previewFile && previewFile.media_type === "video" && (
          <Box sx={{ position: "relative" }}>
            <Box
              component={"video"}
              autoPlay
              controlsList="nodownload"
              controls
              onContextMenu={(e) => e.preventDefault()}
              sx={{
                maxHeight: { md: "600px", mobile: "auto" },
                width: { md: "auto", mobile: "100%" },
                margin: "0 auto",
                borderRadius: "10px"
              }}
            >
              <source src={previewFile.link} />
            </Box>
            {/*<div*/}
            {/*  style={{*/}
            {/*    position: "absolute",*/}
            {/*    top: 0,*/}
            {/*    left: 0,*/}
            {/*    width: "100%",*/}
            {/*    height: "100%",*/}
            {/*    zIndex: 1,*/}
            {/*    pointerEvents: "none",*/}
            {/*  }}*/}
            {/*></div>*/}
          </Box>
        )}
      </Dialog>
    </Box>
  );
};

export default PreviewSwiper;
