import React from "react";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MaterialDialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import useService from "../../../useService";
import CustomHooks from "../../../../../../@components/hooks";
import { Controller, useForm } from "react-hook-form";
import UI from "../../../../../../@components/UI";
import { useTranslation } from "react-i18next";

const Dialog = ({
  albumSpace,
  DAO,
  open,
  onClose,
  data,
  refetch,
  triggerConfetti,
}) => {
  const { albumId } = useParams();
  const { t } = useTranslation();
  const service = useService(DAO?.id);

  const { mutate: submitCard } = CustomHooks.usePost(service.submitCard, {
    onSuccess: async () => {
      await refetch();
      triggerConfetti();
      onClose();
    },
  });

  const {
    control,
    // formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      code: "",
    },
  });

  const onSubmit = async (form) => {
    submitCard({
      album_id: albumId,
      album_space_type: "Code",
      space_id: albumSpace.id,
      code: form.code,
    });
  };
  return (
    <>
      <MaterialDialog
        PaperProps={{
          sx: {
            background: (theme) => theme.palette.dialog.background,
            backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
            position: "relative",
            padding: { md: "60px 20px", mobile: "60px 20px" },
            width: "550px",
            margin: { mobile: "15px", md: "0px" },
          },
        }}
        open={open}
        onClose={onClose}
      >
        <CloseIcon
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 15,
            top: 15,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent
            sx={{
              width: "100%",
              p: "0px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Box sx={{ mt: "10px" }}>
              <Typography variant={"h3"}>{data.data_json.title}</Typography>
              <Typography variant={"subtitle1"} sx={{ mt: "10px" }}>
                {data.data_json.description}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "left", width: "100%" }}>
              <Box sx={{ display: "flex", mt: "20px", pt: "0px" }}>
                <Typography variant={"h4"}>
                  {t("brands.albums.dropdown.code")}
                </Typography>
              </Box>

              <Controller
                name={"code"}
                rules={{ required: true }}
                control={control}
                render={({ field }) => <UI.TextField {...field} />}
              />
            </Box>
            <Box sx={{ mt: "35px" }}>
              <UI.Button
                type={"primary"}
                sx={{
                  width: "100%",
                  height: "50px",
                  background: "#944DFF",
                  "&:hover": {
                    background: "#944DFF",
                  },
                }}
                title={t("buttons.submit")}
              />
            </Box>
          </DialogContent>
        </form>
      </MaterialDialog>
    </>
  );
};

const Action = ({
  element: Component,
  data,
  DAO,
  albumSpace,
  refetch,
  triggerConfetti,
}) => {
  const [open, setOpen] = React.useState(null);

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Box
        sx={{ cursor: "pointer", height: "100%", width: "100%" }}
        onClick={() => setOpen(!open)}
      >
        {Component}
      </Box>

      <Dialog
        triggerConfetti={triggerConfetti}
        refetch={refetch}
        DAO={DAO}
        open={open}
        albumSpace={albumSpace}
        onClose={() => setOpen(false)}
        data={data}
      />
    </Box>
  );
};

Dialog.Action = Action;

export default Dialog;
