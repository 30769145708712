import Box from "@mui/material/Box";
import React from "react";

const FrontSide = ({ item }) => {
  const image = item?.card_image
    ? item?.card_image
    : "https://hazellabs.infura-ipfs.io/ipfs/QmS32S2EX9i2853YWHDiinCgkSJpnCumNdAhMR28nNC8Pc";

  const spaceIsConfirmed = item.album_card.length !== 0;
  const isLocked = item.is_locked;

  return (
    <Box
      key={item.id}
      sx={{
        borderRadius: (theme) => theme.borderRadius.primary,
        backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
        boxShadow: (theme) => theme.palette.album.boxShadow,
        position: "relative",
        cursor: "pointer",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          backgroundColor: (theme) => theme.palette.bgTransparent.secondary,
        },
      }}
    >
      <Box
        component={"img"}
        src={image}
        alt="card-backface"
        sx={{ width: "100%", height: "100%", borderRadius: "5px" }}
      />
      {!spaceIsConfirmed && !isLocked && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1000,
            borderRadius: (theme) => theme.borderRadius.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transition: "all 0.3s ease-in-out",
          }}
        ></Box>
      )}
    </Box>
  );
};

export default FrontSide;
