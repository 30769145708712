import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UI from "../../@components/UI";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import ArrowForwardIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useTranslation } from "react-i18next";
import "./styles.css";
import { Link } from "react-router-dom";

const NewsHeader = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        alignItems: "center",
        mb: { md: "38px", mobile: "22px" },
      }}
    >
      <Typography
        variant={"h3"}
        sx={{
          fontSize: { md: "30px !important", mobile: "24px !important" },
          textAlign: { md: "center", mobile: "center" },
        }}
      >
        {t("explore.news.title")}
      </Typography>
    </Box>
  );
};

const NewsFooter = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Link to={{ pathname: "https://blog.qvrse.io/" }} target="_blank">
        <UI.Button
          type={"secondary"}
          size={"small"}
          sx={{
            width: { md: "240px", mobile: "300px" },
            height: "46px",
          }}
          title={t("explore.news.button.seeAll")}
        />
      </Link>
    </Box>
  );
};

const NewsItem = ({ item }) => {
  const { t, i18n } = useTranslation();
  return (
    <Box
      sx={{
        width: { md: "385px", tablet: "350px", mobile: "300px" },
        height: "auto",
        minHeight: "253.19px",
        borderRadius: (theme) => theme.borderRadius.primary,
        cursor: "pointer",
        backgroundColor: (theme) => theme.palette.bgTransparent.primary,
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          backgroundColor: (theme) => theme.palette.bgTransparent.secondary,
        },
      }}
    >
      <img
        src={item.image}
        alt={item.title}
        style={{
          width: "100%",
          height: "300px",
          objectFit: "cover",
          borderRadius: "10px 10px 0 0",
        }}
      />
      <Box p={{ md: "22px 32px 38px", mobile: "12px 18px 38px" }}>
        <Box
          sx={{
            mb: "12px",
            mt: "5px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              borderRadius: "100px",
              backgroundColor: (theme) =>
                theme.palette.bgTransparent.quaternary,
              width: "fit-content",
              display: "flex",
              alignItems: "center",
              p: "4px 14px",
            }}
          >
            <Typography variant={"h10"}>
              {i18n.language === "sl" ? item.kategorija : item.category}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Typography variant={"subtitle4"}>
              {i18n.language === "sl" ? item.datum : item.date}
            </Typography>
          </Box>
        </Box>

        <Typography variant={"h3"} sx={{ mb: 2, lineHeight: "1.4", mt: 3, pr: {md: "0", tablet: "20px", mobile: "0"} }}>
          {i18n.language === "sl" ? item.naslov : item.title}
        </Typography>
        <Typography variant={"subtitle2"} sx={{ mb: 2 }}>
          {i18n.language === "sl" ? item.opis : item.description}
        </Typography>
        <Link
          to={{
            pathname: item.link,
          }}
          target="_blank"
        >
          <UI.Button
            type={"secondary"}
            size={"small"}
            sx={{
              mt: { md: "24px", mobile: "14px" },
              width: "140px",
              height: "46px",
              display: "flex",
              p: "0",
            }}
            title={
              <Box
                display={"flex"}
                justifyContent={"spaceBetween"}
                alignItems={"center"}
              >
                <Typography variant={"h8"} sx={{ ml: "8px" }}>
                  {t("explore.news.button.readMore")}
                </Typography>
                <ArrowForwardIcon
                  sx={{
                    fontSize: "22px",
                    ml: "5px",
                    color: (theme) => theme.palette.text.primary,
                  }}
                />
              </Box>
            }
          />
        </Link>
      </Box>
    </Box>
  );
};

const NewsSlider = ({ news }) => {
  return (
    <>
      <NewsHeader />
      <Swiper
        autoplay={{
          delay: 6000,
          disableOnInteraction: true,
          pauseOnMouseEnter: false,
        }}
        loop={true}
        loopFillGroupWithBlank={true}
        navigation={false}
        spaceBetween={10}
        modules={[Autoplay, Pagination, Navigation]}
        grabCursor={true}
        slidesPerView={1.2}
        breakpoints={{
          600: {
            slidesPerView: 2,
          },
          1025: {
            slidesPerView: 3,
          },
          1800: {
            slidesPerView: 4,
          },
        }}
        className="newsSwiper"
      >
        {news.map((item, index) => (
          <SwiperSlide key={index}>
            <Box
              sx={{
                display: "flex !important",
                justifyContent: "center !important",
              }}
            >
              <NewsItem item={item} />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
      <NewsFooter />
    </>
  );
};

const Index = () => {
  const newsData = [
    {
      category: "COLLECTOR'S ALBUM",
      kategorija: "ZBIRATELJSKI ALBUM",
      date: "29 Aug 2024",
      datum: "29. avg. 2024",
      title: "Exciting December on Qvrse ",
      naslov: "Nepozabni december na Qvrse",
      description:
        "Events, Missions, Christmas Village and more await this holiday season!",
      opis: "Dogodki, misije, božična vasica in več nas čakajo to praznično sezono.",
      image: "https://christmas.qvrse.io/assets/img/xmas-blog.png",
      link: "https://blog.qvrse.io/2024/08/29/creating-christmas-campaign-with-qvrse/",
    },
    {
      category: "TECHNOLOGY",
      kategorija: "TEHNOLOGIJA",
      date: "22 July 2024",
      datum: "22. jul. 2024",
      title: "Qvrse & Alchemy Integration",
      naslov: "Qvrse in Alchemy Integracija",
      description:
        "Introducing new technologies can be a challenge if they are widely known.",
      opis: "Uvajanje novih tehnologij je lahko izziv, če še niso široko poznane.",
      image:
        "https://blog.qvrse.io/wp-content/uploads/2024/07/Qvrse-blog-720x380.png",
      link: "https://blog.qvrse.io/2024/07/22/qvrse-in-mnozicna-uporaba-web3-tehnologij/",
    },
    {
      category: "COLLECTOR'S ALBUM",
      kategorija: "ZBIRATELJSKI ALBUM",
      date: "8 July 2024",
      datum: "8. jul. 2024",
      title: "The Qvrse NFT Collector’s Album!",
      naslov: "Zbirateljski album Qvrse NFT!",
      description:
        "Qvrse has introduced the Collector's Album for NFT enthusiasts.",
      opis: "Qvrse je za ljubitelje NFT-jev predstavil zbirateljski album Qvrse.",
      image:
        "https://blog.qvrse.io/wp-content/uploads/2024/07/qvrse-album-izpostavitev-1-720x380.jpg",
      link: "https://blog.qvrse.io/2024/07/08/presenting-the-qvrse-nft-collectors-album-%f0%9f%93%9a%e2%9c%a8/",
    },
    {
      category: "NEW POST",
      kategorija: "NOVA OBJAVA",
      date: "14 June 2024",
      datum: "14. jun. 2024",
      title: "Qvrse premieres at Qvrse Experience Day",
      naslov: "Qvrse 1.0 premiera na Qvrse Experience Day",
      description:
        "The time has finally come for Qvrse to be unveiled - 4 July 2024 at 20:00 ...",
      opis: "Končno je prišel čas, da se Qvrse 1.0 predstavi - 4. 7. 2024 ob 20. uri ...",
      image:
        "https://blog.qvrse.io/wp-content/uploads/2024/06/qvrseExperience-banner.jpg",
      link: "https://blog.qvrse.io/2024/06/14/qvrse-1-0-premierno-na-qvrse-experience-day/",
    },
    {
      category: "NFT ticket",
      kategorija: "NFT vstopnica",
      date: "12 Apr 2024",
      datum: "12. apr. 2024",
      title: "QVRSE STAND UP SHOW TICKET",
      naslov: "QVRSE STAND UP VSTOPNICA",
      description:
        "Tin Vodopivec returns with a new stand-up show: 'Elektro Banana.'",
      opis: "Tin Vodopivec se vrača na odre s stand-up komedijo: “Elektro Banana.“",
      image:
        "https://blog.qvrse.io/wp-content/uploads/2024/04/tin-nft_animation_1_1-1.gif",
      link: "https://blog.qvrse.io/2024/04/12/s-qvrse-vstopnico-na-tinov-stand-up/",
    },
    {
      category: "NFT certificate",
      kategorija: "NFT certifikat",
      date: "5 Apr 2024",
      datum: "5. apr. 2024",
      title: "DIVINE KALY NFT CERTIFIED IMAGES",
      naslov: "DIVINE KALY NFT CERTIFICIRANE SLIKE",
      description: "Kaly Kolonič Potočnik introduces new energy paintings ...",
      opis: "Kaly Kolonič Potočnik s svojo znamko energijskih slik predstavlja nekaj ...",
      image:
        "https://blog.qvrse.io/wp-content/uploads/2024/04/BeautyPlus_20240403183935710_save-720x380.jpg",
      link: "https://blog.qvrse.io/2024/04/05/divine-kaly-nft-certificirane-slike/",
    },
    {
      category: "The virtual world",
      kategorija: "Virtualni svet",
      date: "7 Feb 2024",
      datum: "7. febr. 2024",
      title: "MRFYLAND RULES ON QVRSE",
      naslov: "MRFYLAND KRALJUJE NA QVRSU",
      description:
        "MRFY CARD 2.0, which opens the door to an even better MRFYLAND ...",
      opis: "MRFY CARD 2.0, ki odpira vrata v MRFYLAND, je pošla v 45 minutah!",
      image:
        "https://blog.qvrse.io/wp-content/uploads/2024/02/Posnetek-zaslona-2024-02-07-211650-585x380.png",
      link: "https://blog.qvrse.io/2024/02/07/mrfyland-kraljuje-na-qvrsu/",
    },
    {
      category: "The virtual world",
      kategorija: "Virtualni svet",
      date: "30 Jan 2024",
      datum: "30 jan. 2024",
      title: "TONY LAND GUESTS AT THE QVRSE ARENA",
      naslov: "TONY LAND GOSTUJE V QVRSE ARENI",
      description:
        "Tony Cetinski will present his new AI spot in the most innovative way!",
      opis: "Tony Cetinski bo svoj novi AI spot predstavil na najbolj inovativen način!",
      image:
        "https://blog.qvrse.io/wp-content/uploads/2024/01/TONY-CETINSKI-ADI-SOSE-SVE-ILI-NISTA-Screenshot-29-720x380.jpg",
      link: "https://blog.qvrse.io/2024/01/30/tony-land-gostuje-v-qvrse-areni/",
    },
    {
      category: "NFT souvenir",
      kategorija: "NFT spominek",
      date: "26 Jan 2024",
      datum: "26 jan. 2024",
      title: "1st NFT collection by Tony Cetinski",
      naslov: "1. NFT zbirka Tonyja Cetinskega",
      description:
        "Through the virtual party, you can choose Tony Cetinski hairstyle!",
      opis: "Preko virtualne zabave lahko izbereš najboljšo pričesko Tonyja Cetinskega!",
      image:
        "https://blog.qvrse.io/wp-content/uploads/2024/01/cetinski.d9d12410-1.jpg",
      link: "https://blog.qvrse.io/2024/01/26/tony-hair/",
    },
  ];

  return (
    <div>
      <NewsSlider news={newsData} />
    </div>
  );
};

export default Index;
