import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Typography from "@mui/material/Typography";

const Overlay = ({ open, onClose, onDelete }) => {
  if (!open) return null;

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 900,
          borderRadius: (theme) => theme.borderRadius.secondary,
        }}
        onClick={onClose}
      >
        <Box
          onClick={(e) => e.stopPropagation()}
          sx={{
            p: 2,
          }}
        >
          <IconButton
            onClick={() => {
              onDelete();
            }}
          >
            <DeleteForeverIcon sx={{ color: "#EF5350" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant={"h8"} sx={{ color: "#EF5350" }}>
                Delete
              </Typography>
            </Box>
          </IconButton>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};
const Action = ({ element: Component, onDelete }) => {
  const [open, setOpen] = React.useState(null);

  return (
    <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
      <Box
        sx={{ cursor: "pointer", width: "100%", height: "100%" }}
        onClick={() => setOpen(!open)}
      >
        {Component}
      </Box>

      <Overlay
        open={open}
        onDelete={() => onDelete()}
        onClose={() => setOpen(false)}
      />
    </Box>
  );
};

Overlay.Action = Action;

export default Overlay;
