import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import UI from "../../@components/UI";
import Grid from "@mui/material/Grid";
import MaterialDialog from "@mui/material/Dialog";
import { Controller, useForm, useWatch } from "react-hook-form";
import Alert from "@mui/material/Alert";
import useHandleErrors from "../../@components/hooks/useHandleErrors";
import { useSnackbar } from "notistack";
import useService from "../useService";
import { useUserContext } from "../../@components/userContext";
import { useTranslation } from "react-i18next";

const RegisterDialog = ({ toggleOpen }) => {
  const { t } = useTranslation();
  const { user: loggedInUser } = useUserContext();
  const [isLoading, setIsLoading] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const { handleApiError } = useHandleErrors();
  const { enqueueSnackbar } = useSnackbar();
  const service = useService();

  const resetPassword = async () => {
    await service
      .resetPassword({ email: loggedInUser?.email })
      .then(async () => {
        enqueueSnackbar(t("profile.dialogs.resetPassword.snackbar"), {
          variant: "success",
        });
        setOpen(false);
      })
      .catch((error) => {
        handleApiError(error);
        setIsLoading(false);
      });
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      old_password: "",
      new_password: "",
    },
    mode: "all",
  });

  const new_password = useWatch({ control, name: "new_password" });

  const onSubmit = async (formData) => {
    setIsLoading(true);
    const data = {
      old_password: formData.old_password,
      new_password: formData.new_password,
    };

    await service
      .changePassword(data)
      .then(async () => {
        enqueueSnackbar(t("profile.dialogs.resetPassword.snackbar2"), {
          variant: "success",
        });
        setIsLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        handleApiError(error);
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    toggleOpen && setOpen(!open);
    // eslint-disable-next-line
  }, [toggleOpen]);
  const handleClose = () => {
    setOpen(false);
    reset();
  };
  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          padding: { tablet: "40px 30px 50px", mobile: "40px 10px" },
          width: "700px",
          margin: { mobile: "0px" },
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent sx={{ p: { mobile: "0px" } }}>
        <CloseIcon
          onClick={() => {
            setOpen(false);
            reset();
          }}
          sx={{
            position: "absolute",
            right: 20,
            top: 30,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />

        <Grid container>
          <Grid item mobile={12} md={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography
                variant={"h3"}
                sx={{
                  textAlign: { md: "center", mobile: "left" },
                  px: "10px",
                }}
              >
                {t("profile.dialogs.resetPassword.title")}
              </Typography>

              <Box sx={{ mt: { md: "30px", mobile: "10px" }, px: "10px" }}>
                <Typography
                  variant={"subtitle2"}
                  sx={{ mb: { md: "40px", mobile: "30px" } }}
                >
                  {t("profile.dialogs.resetPassword.subtitle")}
                </Typography>
                <Box sx={{ mt: "26px" }}>
                  <Typography variant={"h6"} sx={{ mb: "5px" }}>
                    {t("profile.dialogs.resetPassword.currentPassword")}
                  </Typography>
                  <Controller
                    name={"old_password"}
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <UI.PasswordField
                        {...field}
                        fullWidth
                        placeholder={t(
                          "profile.dialogs.resetPassword.currentPasswordPlaceholder"
                        )}
                      />
                    )}
                  />
                  <Box sx={{ mt: "15px" }}>
                    {errors.old_password && (
                      <Alert variant="outlined" severity="error">
                        {errors.old_password?.type === "required" &&
                          t(
                            "profile.dialogs.resetPassword.currentPasswordRequired"
                          )}
                      </Alert>
                    )}
                  </Box>
                  <Typography
                    variant={"subtitle2"}
                    sx={{
                      mt: { md: "10px", mobile: "0px" },
                    }}
                  >
                    {t("profile.dialogs.resetPassword.forgot")}{" "}
                    <Box
                      onClick={() => resetPassword()}
                      component={"span"}
                      sx={{
                        cursor: "pointer",
                        color: (theme) => theme.palette.colors.primary,
                      }}
                    >
                      {t("profile.dialogs.resetPassword.forgotLink")}
                    </Box>
                  </Typography>
                </Box>
                <Box sx={{ mt: "26px" }}>
                  <Typography variant={"h6"} sx={{ mb: "5px" }}>
                    {t("profile.dialogs.resetPassword.newPassword")}
                  </Typography>
                  <Controller
                    name={"new_password"}
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        value: /^.{8,}$/,
                        message: t(
                          "profile.dialogs.resetPassword.newPasswordPattern"
                        ),
                      },
                    }}
                    render={({ field }) => (
                      <UI.PasswordField
                        {...field}
                        fullWidth
                        placeholder={t(
                          "profile.dialogs.resetPassword.newPasswordPlaceholder"
                        )}
                      />
                    )}
                  />
                  <Box sx={{ mt: "15px" }}>
                    {errors.new_password && (
                      <Alert variant="outlined" severity="error">
                        {errors.new_password?.type === "pattern" &&
                          errors.new_password.message}
                        {errors.new_password?.type === "required" &&
                          t(
                            "profile.dialogs.resetPassword.newPasswordRequired"
                          )}
                      </Alert>
                    )}
                  </Box>
                </Box>
                <Box sx={{ mt: "26px" }}>
                  <Typography variant={"h6"} sx={{ mb: "5px" }}>
                    {t("profile.dialogs.resetPassword.confirmPassword")}
                  </Typography>
                  <Controller
                    name={"confirmPassword"}
                    control={control}
                    rules={{
                      required: true,
                      validate: (value) =>
                        value === new_password ||
                        t(
                          "profile.dialogs.resetPassword.confirmPasswordValidate"
                        ),
                    }}
                    render={({ field }) => (
                      <UI.PasswordField
                        {...field}
                        fullWidth
                        placeholder={t(
                          "profile.dialogs.resetPassword.confirmPasswordPlaceholder"
                        )}
                      />
                    )}
                  />
                  <Box sx={{ mt: "15px" }}>
                    {errors.confirmPassword && (
                      <Alert variant="outlined" severity="error">
                        {errors.confirmPassword?.type === "validate" &&
                          errors.confirmPassword.message}
                        {errors.confirmPassword?.type === "required" &&
                          t(
                            "profile.dialogs.resetPassword.confirmPasswordRequired"
                          )}
                      </Alert>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  mt: "45px",
                  px: "15px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <UI.Button
                  disabled={Object.keys(errors).length}
                  sx={{ width: "340px" }}
                  title={t("profile.dialogs.resetPassword.button")}
                  type={"primary"}
                />
              </Box>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <UI.Busy.FullscreenIndicator show={isLoading}>
        <Typography variant={"subtitle1"}>
          {t("profile.dialogs.resetPassword.loading")}
        </Typography>
        <Typography variant={"subtitle1"}>
          {t("profile.dialogs.resetPassword.loading2")}
        </Typography>
      </UI.Busy.FullscreenIndicator>
    </MaterialDialog>
  );
};

export default RegisterDialog;
