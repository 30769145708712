import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { useFormData } from "../context";
import Paper from "@mui/material/Paper";
import { useFieldArray, useForm } from "react-hook-form";
import UI from "../../../../../@components/UI";
import paypalLogo from "../../../../../static/paypal.png";
import stripeLogo from "../../../../../static/stripe.png";
import cryptoLogo from "../../../../../static/crypto.png";
import freeLogo from "../../../../../static/free.png";
import { useUserContext } from "../../../../../@components/userContext";
import euroLogo from "../../../../../static/euroLogo.png";
import MenuItem from "@mui/material/MenuItem";
import Price from "./Price";
import CustomHooks from "../../../../../@components/hooks";
import useService from "../../../collection/useService";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Royalty from "./Royalty";
import { Breadcrumbs } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";

const useStyles = makeStyles((theme) => ({
  backButtonOuter: {
    "&:hover .backArrow": {
      transform: "translateX(-50%)",
    },
  },

  inputOuter: {
    marginTop: "10px",

    display: "flex",
    flexDirection: "column",
  },

  backButton: {
    textAlign: "left",
    fontFamily: "Quantico",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "13px",
    lineHeight: "20px",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
  },
}));

const Payment = ({ onSave, draft, DAO, nextStep, previouseSteps }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const service = useService();
  const { user: loggedInUser } = useUserContext();
  const { data, setFormValues } = useFormData();

  const { data: paymentOptions } = CustomHooks.useFetch(
    ["paymentOptions"],
    service.paymentOptions
  );

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    setError,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      main_utility: data?.main_utility,
      extra_utilities: data?.extra_utilities || [],
      payment: data?.payment,
      totalPrice: data?.totalPrice,
      isFreeMintEnabled: data?.isFreeMintEnabled,
      royalty: {
        fee: data?.royalty?.fee,
        fee_address: DAO?.treasury_address,
      },
    },
    mode: "all",
  });

  console.log("errors: ", errors);

  const { fields: extraUtilities } = useFieldArray({
    control: control,
    name: "extra_utilities",
  });

  const selectedCrypto = watch("payment.crypto.enabled");
  const selectedFreeMint = watch("isFreeMintEnabled");
  const selectedPaypal = watch("payment.fiat.paypal");
  const selectedStripe = watch("payment.fiat.stripe");
  const selectedFiatCurrency = watch("payment.fiat.currency");
  const selectedCryptoCurrency = watch("payment.crypto.currency");
  const totalPrice = watch("totalPrice");

  React.useEffect(() => {
    if (totalPrice && totalPrice > 0) {
      clearErrors("totalPrice");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPrice]);

  // Reset form with draft data.
  React.useEffect(() => {
    if (draft) {
      setFormValues(draft.data);
      reset({
        main_utility: draft.data.main_utility,
        extra_utilities: draft.data.extra_utilities,
        payment: draft.data.payment,
        isFreeMintEnabled: draft.data.isFreeMintEnabled,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft]);

  const onSubmit = async (values, action) => {
    const allValues = {
      ...values,
      payment: {
        ...values.payment,
        crypto: values.payment.crypto.enabled
          ? {
              ...values.payment.crypto,
              token: paymentOptions.find(
                (option) => option.name === values.payment.crypto.currency
              ),
            }
          : { enabled: false, currency: "MATIC", token: null },
      },
    };

    switch (action) {
      case "save_and_continue": {
        await onSave(allValues, false);
        nextStep();

        break;
      }
      case "save": {
        await onSave(allValues, true);
        break;
      }
      default: {
        await onSave(allValues, true);
        break;
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit((values) => {
        if (values.totalPrice === "0.00" && !values.isFreeMintEnabled) {
          setError("totalPrice", {
            type: "manual",
            message: t(
              "brands.collection.create.payment.price.totalPriceError"
            ),
          });
        } else {
          onSubmit(values, "save_and_continue");
        }
      })}
    >
      <Box
        sx={{
          mt: { md: "15px", mobile: "30px" },
          p: { md: "40px 65px", mobile: "0px 10px" },
          mb: "10px",
        }}
      >
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: "30px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                cursor: "pointer",
                color: (theme) => theme.palette.text.primary,
              },
            }}
            onClick={() => {
              history.push(`/brand/${DAO.id}`);
            }}
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            {t("breadcrumbs.home")}
          </Box>
          <Box
            underline="hover"
            sx={{
              display: "flex",
              alignItems: "center",
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                cursor: "pointer",
                color: (theme) => theme.palette.text.primary,
              },
            }}
            onClick={() => {
              history.push(`/brand/${DAO.id}/collection`);
            }}
          >
            {t("breadcrumbs.collections")}
          </Box>
          <Box
            underline="hover"
            sx={{
              display: "flex",
              alignItems: "center",
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                cursor: "pointer",
                color: (theme) => theme.palette.text.primary,
              },
            }}
            onClick={() => previouseSteps(3)}
          >
            {t("breadcrumbs.basicInfo")}
          </Box>
          <Box
            underline="hover"
            sx={{
              display: "flex",
              alignItems: "center",
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                cursor: "pointer",
                color: (theme) => theme.palette.text.primary,
              },
            }}
            onClick={() => previouseSteps(2)}
          >
            {t("breadcrumbs.mainUtility")}
          </Box>
          <Box
            underline="hover"
            sx={{
              display: "flex",
              alignItems: "center",
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                cursor: "pointer",
                color: (theme) => theme.palette.text.primary,
              },
            }}
            onClick={() => previouseSteps(1)}
          >
            {t("breadcrumbs.extraUtilities")}
          </Box>
          <Typography
            sx={{ display: "flex", alignItems: "center", fontFamily: "Open Sans", fontSize: "14px", fontWeight: "600" }}
            color="text.primary"
          >
            {t("breadcrumbs.payment")}
          </Typography>
        </Breadcrumbs>
        <Box
          sx={{
            mb: { mobile: "10px", md: "20px" },
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <Box>
            <Typography variant={"h3"} sx={{ mb: "4px" }}>
              {t("brands.collection.create.payment.title")}
            </Typography>
            <Typography variant={"subtitle2"}>
              {t("brands.collection.create.payment.subtitle")}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant={"subtitle2"}
              sx={{
                mt: "20px",
                display: "flex",
                mb: "0px",
              }}
            >
              <Alert variant="outlined" severity="info">
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  {`To set up more payment options go to `}{" "}
                  <Box
                    sx={{
                      color: (theme) => theme.palette.text.linkBlue,
                      textDecorationColor: (theme) =>
                        theme.palette.text.linkBlue,
                      cursor: "pointer",
                      fontWeight: "bold",
                      textDecoration: "underline",
                      ml: "5px",
                      transition: "all 0.3s ease-in-out",
                      "&:hover": {
                        color: (theme) => theme.palette.text.linkBlueHover,
                        textDecorationColor: (theme) =>
                          theme.palette.text.linkBlueHover,
                      },
                    }}
                    onClick={() => history.push(`/brand/213/settings/payment`)}
                    component={"span"}
                  >
                    settings
                  </Box>
                </Box>
              </Alert>
            </Typography>
          </Box>
        </Box>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item mobile={12} md={12} sx={{ textAlign: "left" }}>
            <Box className={classes.inputOuter}>
              <Box sx={{ display: "flex" }}>
                <Typography variant={"h4"}>
                  {t("brands.collection.create.payment.paymentOption.title")}
                </Typography>
              </Box>
            </Box>
            {DAO?.treasury_address && (
              <Paper
                elevation={0}
                sx={{
                  width: "100%",
                  backgroundColor: selectedCrypto
                    ? (theme) => theme.palette.bgTransparent.primary
                    : "transparent",
                  p: { md: "5px 25px ", mobile: "5px 10px" },
                }}
              >
                <Box className={classes.inputOuter}>
                  <Box sx={{ display: "flex" }}>
                    <UI.Checkbox
                      onClick={() => {
                        setValue("isFreeMintEnabled", false);
                        setValue("payment.crypto.enabled", true);
                        setValue("payment.fiat.paypal", false);
                        setValue("payment.fiat.stripe", false);
                      }}
                      value={selectedCrypto}
                      nomargin={"true"}
                      label={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box
                            component={"img"}
                            src={cryptoLogo}
                            sx={{ width: "40px", ml: "15px" }}
                          />
                          <Typography
                            variant={"h8"}
                            sx={{
                              ml: "15px",
                            }}
                          >
                            {t(
                              "brands.collection.create.payment.paymentOption.crypto.title"
                            )}
                          </Typography>
                        </Box>
                      }
                    />
                  </Box>
                  {selectedCrypto && (
                    <Box className={classes.inputOuter} sx={{ mb: "25px" }}>
                      <Box sx={{ display: "flex", mb: "10px", mt: "20px" }}>
                        <Typography variant={"h6"}>
                          {t(
                            "brands.collection.create.payment.paymentOption.crypto.subtitle"
                          )}
                        </Typography>
                      </Box>

                      <UI.Select
                        defaultValue={
                          data?.payment?.crypto.currency === "MATIC" ||
                          data?.payment?.crypto.currency === "USDT" ||
                          data?.payment?.crypto.currency === "USDC"
                            ? data?.payment?.crypto.currency
                            : "MATIC"
                        }
                        onChange={(e) => {
                          setValue("payment.crypto.currency", e.target.value);
                        }}
                      >
                        {paymentOptions?.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.name}>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Box
                                  sx={{
                                    width: "23px",
                                    height: "23px",
                                    mr: "10px",
                                  }}
                                >
                                  <UI.PaymentIcon currency={item.name} />
                                </Box>

                                <Typography variant={"h6"}>
                                  {item.name}
                                </Typography>
                              </Box>
                            </MenuItem>
                          );
                        })}
                      </UI.Select>
                    </Box>
                  )}
                </Box>
              </Paper>
            )}
            {DAO?.fiat_payment_options?.paypal && (
              <Paper
                elevation={0}
                sx={{
                  width: "100%",
                  backgroundColor: selectedPaypal
                    ? (theme) => theme.palette.bgTransparent.primary
                    : "transparent",
                  p: { md: "5px 25px ", mobile: "5px 10px" },
                }}
              >
                <Box className={classes.inputOuter}>
                  <Box sx={{ display: "flex" }}>
                    <UI.Checkbox
                      onClick={() => {
                        clearErrors();
                        setValue("isFreeMintEnabled", false);
                        setValue("payment.fiat.paypal", true);
                        setValue("payment.crypto.enabled", false);
                        setValue("payment.fiat.currency", "EUR");
                      }}
                      value={selectedPaypal}
                      nomargin={"true"}
                      label={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box
                            component={"img"}
                            src={paypalLogo}
                            sx={{ width: "40px", ml: "15px" }}
                          />
                          <Typography
                            variant={"h8"}
                            sx={{
                              ml: "15px",
                            }}
                          >
                            PAYPAL
                          </Typography>
                        </Box>
                      }
                    />
                  </Box>
                  {selectedPaypal && !selectedStripe && (
                    <Box className={classes.inputOuter} sx={{ mb: "25px" }}>
                      <Box sx={{ display: "flex", mb: "10px", mt: "20px" }}>
                        <Typography variant={"h6"}>
                          {t(
                            "brands.collection.create.payment.paymentOption.fiat.subtitle"
                          )}
                        </Typography>
                      </Box>
                      <UI.Select
                        defaultValue={
                          data?.payment?.fiat.currency === "EUR" ||
                          data?.payment?.fiat.currency === "USD"
                            ? data?.payment?.fiat.currency
                            : "EUR"
                        }
                        onChange={(e) => {
                          setValue("payment.fiat.currency", e.target.value);
                        }}
                      >
                        <MenuItem value={"EUR"}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box
                              component={"img"}
                              src={euroLogo}
                              sx={{ width: "23px", height: "23px", mr: "10px" }}
                            />
                            <Typography variant={"h6"}>EUR</Typography>
                          </Box>
                        </MenuItem>
                      </UI.Select>
                    </Box>
                  )}
                </Box>
              </Paper>
            )}
            {DAO?.fiat_payment_options?.stripe && (
              <Paper
                elevation={0}
                sx={{
                  width: "100%",
                  backgroundColor: selectedStripe
                    ? (theme) => theme.palette.bgTransparent.primary
                    : "transparent",
                  p: { md: "5px 25px ", mobile: "5px 10px" },
                }}
              >
                <Box className={classes.inputOuter}>
                  <Box sx={{ display: "flex" }}>
                    <UI.Checkbox
                      onClick={() => {
                        clearErrors();
                        setValue("isFreeMintEnabled", false);
                        setValue("payment.fiat.stripe", true);
                        setValue("payment.crypto.enabled", false);
                        setValue("payment.fiat.currency", "EUR");
                      }}
                      value={selectedStripe}
                      nomargin={"true"}
                      label={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box
                            component={"img"}
                            src={stripeLogo}
                            sx={{ width: "40px", ml: "15px" }}
                          />
                          <Typography
                            variant={"h8"}
                            sx={{
                              ml: "15px",
                            }}
                          >
                            STRIPE
                          </Typography>
                        </Box>
                      }
                    />
                  </Box>
                  {selectedStripe && (
                    <Box className={classes.inputOuter} sx={{ mb: "25px" }}>
                      <Box sx={{ display: "flex", mb: "10px", mt: "20px" }}>
                        <Typography variant={"h6"}>
                          {t(
                            "brands.collection.create.payment.paymentOption.crypto.subtitle"
                          )}
                        </Typography>
                      </Box>

                      <UI.Select
                        defaultValue={
                          data?.payment?.fiat.currency === "EUR" ||
                          data?.payment?.fiat.currency === "USD"
                            ? data?.payment?.fiat.currency
                            : "EUR"
                        }
                        onChange={(e) => {
                          setValue("payment.fiat.currency", e.target.value);
                        }}
                      >
                        <MenuItem value={"EUR"}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box
                              component={"img"}
                              src={euroLogo}
                              sx={{ width: "23px", height: "23px", mr: "10px" }}
                            />
                            <Typography variant={"h6"}>EUR</Typography>
                          </Box>
                        </MenuItem>
                      </UI.Select>
                    </Box>
                  )}
                </Box>
              </Paper>
            )}
            <Paper
              elevation={0}
              sx={{
                width: "100%",
                backgroundColor: selectedFreeMint
                  ? (theme) => theme.palette.bgTransparent.primary
                  : "transparent",
                p: { md: "5px 25px ", mobile: "5px 10px" },
              }}
            >
              <Box
                className={classes.inputOuter}
                sx={{ mb: "10px !important" }}
              >
                <Box sx={{ display: "flex" }}>
                  <UI.Checkbox
                    onClick={() => {
                      clearErrors();
                      setValue("isFreeMintEnabled", true);
                      setValue("payment.crypto.enabled", false);
                      setValue("payment.fiat.stripe", false);
                      setValue("payment.fiat.paypal", false);
                      setValue("payment.fiat.currency", "MATIC");
                    }}
                    value={selectedFreeMint}
                    nomargin={"true"}
                    label={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                          component={"img"}
                          src={freeLogo}
                          sx={{ width: "40px", ml: "15px" }}
                        />
                        <Typography
                          variant={"h8"}
                          sx={{
                            ml: "15px",
                          }}
                        >
                          {t(
                            "brands.collection.create.payment.paymentOption.free.title"
                          )}
                        </Typography>
                      </Box>
                    }
                  />
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item mobile={12} md={12} sx={{ mt: "20px", textAlign: "left" }}>
            <Royalty
              control={control}
              setValue={setValue}
              errors={errors}
              DAO={DAO}
              brandFeeAddress={DAO?.treasury_address}
            />
          </Grid>
          <Grid
            item
            mobile={12}
            md={12}
            sx={{
              mt: "15px",
              display: selectedFreeMint === false ? "block" : "none",
            }}
          >
            <Box className={classes.inputOuter}>
              <Box sx={{ display: "flex" }}>
                <Typography variant={"h4"}>
                  {t("brands.collection.create.payment.price.title")}
                </Typography>
              </Box>
              <Price
                isFreeMint={selectedFreeMint}
                control={control}
                setValue={setValue}
                errors={errors}
                utilities={extraUtilities}
                currency={
                  selectedCrypto ? selectedCryptoCurrency : selectedFiatCurrency
                }
              />
              {errors?.totalPrice?.type === "manual" && (
                <Box sx={{ mt: "15px" }}>
                  <Alert variant="outlined" severity="error">
                    {errors?.totalPrice?.message}
                  </Alert>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            width: "100%",
            mt: "60px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "210px" }}>
              <UI.Button
                disabled={
                  !!Object.keys(errors).length || !loggedInUser?.email_verified
                }
                sx={{
                  filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
                }}
                title={t("buttons.saveAndContinue")}
                type={"primary"}
              />
              <UI.Button
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const allValues = getValues();

                  await onSubmit(allValues, "save");
                }}
                disabled={!loggedInUser?.email_verified}
                sx={{
                  mt: "10px",
                  filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
                }}
                title={t("buttons.saveForLater")}
                type={"tertiary"}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default Payment;
