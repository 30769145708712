import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import UI from "../../@components/UI";
import { makeStyles } from "@mui/styles";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import useService from "../useService";
import useHandleErrors from "../../@components/hooks/useHandleErrors";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import ResetPasswordButton from "./ResetPasswordButton";
// import InputAdornment from "@mui/material/InputAdornment";
// import linkedInIcon from "../../static/linkedin.png";
// import discordIcon from "../../static/discord.png";
// import xIcon from "../../static/x.png";
// import telegramIcon from "../../static/telegram.png";
// import instagramIcon from "../../static/instagram.png";
// import facebookIcon from "../../static/facebook.png";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles((theme) => ({
  inputOuter: {
    marginTop: "5px",
    marginBottom: "15px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const Index = ({ user, refetch }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const service = useService();
  const { enqueueSnackbar } = useSnackbar();
  const { handleApiError } = useHandleErrors();
  const [avatarPreview, setAvatarPreview] = React.useState(null);
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      firstname: user?.firstname,
      lastname: user?.lastname,
      description: user?.description,
      webpage_url: user?.webpage_url,
      username: user?.username,
      email: user?.email,
      avatar_url: user?.avatar_url,
    },
  });

  useEffect(() => {
    if (user) {
      reset({
        firstname: user?.firstname,
        lastname: user?.lastname,
        description: user?.description,
        webpage_url: user?.webpage_url,
        username: user?.username,
        email: user?.email,
        avatar_url: user?.avatar_url,
        discord: user?.discord,
        telegram: user?.telegram,
        twitter: user?.twitter,
        instagram: user?.instagram,
        linkedin: user?.linkedin,
        facebook: user?.facebook,
      });
      setAvatarPreview({
        ipfs: user?.avatar_url,
        file: { type: "image/" },
      });
    }
    // eslint-disable-next-line
  }, [user]);

  const sendConfirmationEmail = async () => {
    await service
      .sendConfirmationEmail()
      .then(() => {
        enqueueSnackbar(t("profile.settings.snackbar"), {
          variant: "success",
        });
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const onSubmit = async (form) => {
    const data = {
      ...form,
      avatar_url: form?.avatar_url?.ipfs,
    };
    await service
      .save(data)
      .then(() => {
        enqueueSnackbar(t("profile.settings.snackbar2"), {
          variant: "success",
        });
        refetch();
      })
      .catch((error) => {
        handleApiError(error);
      });
  };


  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: { lg: "10px 70px 20px", md: "10px 20px 20px 90px", tablet: "0 0 20px" },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant={"h3"}>
            {t("profile.settings.title")}
          </Typography>
          <Typography variant={"subtitle2"} sx={{ mt: "6px", mb: { lg: "25px", md: "20px", tablet: "15px"  } }}>
            {t("profile.settings.subtitle")}
          </Typography>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={12} mobile={12}>
              <Box
                sx={{
                  mt: "10px",
                }}
              >
                <Box sx={{ display: "flex", mb: "8px" }}>
                  <Typography variant={"h6"}>
                    {t("profile.settings.profileImage.title")}
                  </Typography>
                  <UI.Tooltip>
                    {t("profile.settings.profileImage.tooltip")}
                  </UI.Tooltip>
                </Box>
                <Controller
                  name={"avatar_url"}
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <UI.Upload
                      {...field}
                      id={"avatar"}
                      noPadding
                      noHeight
                      avatarPreviewWidth={"150px"}
                      avatarPreviewHeight={"150px"}
                      showAvatarPreview
                      defaultValue={avatarPreview}
                      title={t("profile.settings.profileImage.uploadTitle")}
                      acceptFiles={"image/jpeg,image/gif,image/png"}
                    />
                  )}
                />
              </Box>
            </Grid>

            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "8px" }}>
                  <Typography variant={"h6"}>
                    {t("profile.settings.email.title")}
                  </Typography>
                </Box>
                <Controller
                  name={"email"}
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <UI.TextField
                      {...field}
                      disabled={user?.email_verified}
                      placeholder={t("profile.settings.email.placeholder")}
                    />
                  )}
                />
                {user?.email_verified === false &&
                  user?.email !== "" &&
                  user?.email != null && (
                    <Box sx={{ mt: "10px" }}>
                      <Alert variant="outlined" severity="info">
                        {t("profile.settings.email.alert")}
                      </Alert>
                      <Box sx={{ mt: "10px" }}>
                        <UI.Button
                          disabled={!user?.email}
                          onClick={async (e) => {
                            e.preventDefault();
                            await sendConfirmationEmail();
                          }}
                          type={"primary"}
                          title={t("profile.settings.email.button")}
                          sx={{ maxWidth: { mobile: "100%", tablet: "320px" } }}
                        />
                      </Box>
                    </Box>
                  )}
                {(user?.email === "" || user?.email == null) && (
                  <Box sx={{ mt: "10px" }}>
                    <Alert variant="outlined" severity="error">
                      {t("profile.settings.email.error.required")}
                    </Alert>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "8px" }}>
                  <Typography variant={"h6"}>
                    {t("profile.settings.username.title")}
                  </Typography>
                </Box>

                <Controller
                  name={"username"}
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <UI.TextField
                      {...field}
                      placeholder={t("profile.settings.username.placeholder")}
                    />
                  )}
                />
              </Box>
            </Grid>

            {/*<Grid item md={12} mobile={12}>*/}
            {/*  <Box className={classes.inputOuter}>*/}
            {/*    <Box sx={{ display: "flex", mb: "8px" }}>*/}
            {/*      <Typography variant={"h6"}>*/}
            {/*        {t("profile.settings.description.title")}*/}
            {/*      </Typography>*/}
            {/*    </Box>*/}

            {/*    <Controller*/}
            {/*      name={"description"}*/}
            {/*      control={control}*/}
            {/*      rules={{ required: false }}*/}
            {/*      render={({ field }) => (*/}
            {/*        <UI.TextField*/}
            {/*          multiline*/}
            {/*          rows={5}*/}
            {/*          {...field}*/}
            {/*          placeholder={t(*/}
            {/*            "profile.settings.description.placeholder"*/}
            {/*          )}*/}
            {/*        />*/}
            {/*      )}*/}
            {/*    />*/}
            {/*  </Box>*/}
            {/*</Grid>*/}
            {/*<Grid item md={12} mobile={12}>*/}
            {/*  <Box className={classes.inputOuter}>*/}
            {/*    <Box sx={{ display: "flex", mb: "8px" }}>*/}
            {/*      <Typography variant={"h6"}>*/}
            {/*        {t("profile.settings.webpage.title")}*/}
            {/*      </Typography>*/}
            {/*    </Box>*/}

            {/*    <Controller*/}
            {/*      name={"webpage_url"}*/}
            {/*      control={control}*/}
            {/*      rules={{ required: false }}*/}
            {/*      render={({ field }) => (*/}
            {/*        <UI.TextField*/}
            {/*          {...field}*/}
            {/*          placeholder={t("profile.settings.webpage.placeholder")}*/}
            {/*        />*/}
            {/*      )}*/}
            {/*    />*/}
            {/*  </Box>*/}
            {/*</Grid>*/}
            {/*<Grid item md={12} mobile={12}>*/}
            {/*  <Box className={classes.inputOuter}>*/}
            {/*    <Box sx={{ display: "flex", mb: "8px" }}>*/}
            {/*      <Typography variant={"h6"}>*/}
            {/*        {t("profile.settings.socials.title")}*/}
            {/*      </Typography>*/}
            {/*    </Box>*/}

            {/*    <Controller*/}
            {/*      name={"linkedin"}*/}
            {/*      control={control}*/}
            {/*      rules={{ required: false }}*/}
            {/*      render={({ field }) => (*/}
            {/*        <UI.TextField*/}
            {/*          startAdornment={*/}
            {/*            <InputAdornment position="start">*/}
            {/*              <Box*/}
            {/*                component={"img"}*/}
            {/*                src={linkedInIcon}*/}
            {/*                alt="linkedin-icon"*/}
            {/*                width={"35px"}*/}
            {/*                height={"35px"}*/}
            {/*                sx={{ mr: "3px" }}*/}
            {/*              />*/}
            {/*            </InputAdornment>*/}
            {/*          }*/}
            {/*          {...field}*/}
            {/*          start*/}
            {/*          placeholder={"URL"}*/}
            {/*        />*/}
            {/*      )}*/}
            {/*    />*/}
            {/*  </Box>*/}

            {/*  <Box className={classes.inputOuter} sx={{ mt: "0px !important" }}>*/}
            {/*    <Controller*/}
            {/*      name={"discord"}*/}
            {/*      control={control}*/}
            {/*      rules={{ required: false }}*/}
            {/*      render={({ field }) => (*/}
            {/*        <UI.TextField*/}
            {/*          {...field}*/}
            {/*          startAdornment={*/}
            {/*            <InputAdornment position="start">*/}
            {/*              <Box*/}
            {/*                component={"img"}*/}
            {/*                src={discordIcon}*/}
            {/*                alt="discord-icon"*/}
            {/*                width={"35px"}*/}
            {/*                height={"35px"}*/}
            {/*                sx={{ mr: "3px" }}*/}
            {/*              />*/}
            {/*            </InputAdornment>*/}
            {/*          }*/}
            {/*          placeholder={"URL"}*/}
            {/*        />*/}
            {/*      )}*/}
            {/*    />*/}
            {/*  </Box>*/}

            {/*  <Box className={classes.inputOuter} sx={{ mt: "0px !important" }}>*/}
            {/*    <Controller*/}
            {/*      name={"x"}*/}
            {/*      control={control}*/}
            {/*      rules={{ required: false }}*/}
            {/*      render={({ field }) => (*/}
            {/*        <UI.TextField*/}
            {/*          {...field}*/}
            {/*          startAdornment={*/}
            {/*            <InputAdornment position="start">*/}
            {/*              <Box*/}
            {/*                component={"img"}*/}
            {/*                src={xIcon}*/}
            {/*                alt="twitter-icon"*/}
            {/*                width={"35px"}*/}
            {/*                height={"35px"}*/}
            {/*                sx={{ mr: "3px" }}*/}
            {/*              />*/}
            {/*            </InputAdornment>*/}
            {/*          }*/}
            {/*          placeholder={"URL"}*/}
            {/*        />*/}
            {/*      )}*/}
            {/*    />*/}
            {/*  </Box>*/}

            {/*  <Box className={classes.inputOuter} sx={{ mt: "0px !important" }}>*/}
            {/*    <Controller*/}
            {/*      name={"telegram"}*/}
            {/*      control={control}*/}
            {/*      rules={{ required: false }}*/}
            {/*      render={({ field }) => (*/}
            {/*        <UI.TextField*/}
            {/*          {...field}*/}
            {/*          startAdornment={*/}
            {/*            <InputAdornment position="start">*/}
            {/*              <Box*/}
            {/*                component={"img"}*/}
            {/*                src={telegramIcon}*/}
            {/*                alt="telegram-icon"*/}
            {/*                width={"35px"}*/}
            {/*                height={"35px"}*/}
            {/*                sx={{ mr: "3px" }}*/}
            {/*              />*/}
            {/*            </InputAdornment>*/}
            {/*          }*/}
            {/*          placeholder={"URL"}*/}
            {/*        />*/}
            {/*      )}*/}
            {/*    />*/}
            {/*  </Box>*/}

            {/*  <Box className={classes.inputOuter} sx={{ mt: "0px !important" }}>*/}
            {/*    <Controller*/}
            {/*      name={"instagram"}*/}
            {/*      control={control}*/}
            {/*      rules={{ required: false }}*/}
            {/*      render={({ field }) => (*/}
            {/*        <UI.TextField*/}
            {/*          {...field}*/}
            {/*          startAdornment={*/}
            {/*            <InputAdornment position="start">*/}
            {/*              <Box*/}
            {/*                component={"img"}*/}
            {/*                src={instagramIcon}*/}
            {/*                alt="instagram-icon"*/}
            {/*                width={"35px"}*/}
            {/*                height={"35px"}*/}
            {/*                sx={{ mr: "3px" }}*/}
            {/*              />*/}
            {/*            </InputAdornment>*/}
            {/*          }*/}
            {/*          placeholder={"URL"}*/}
            {/*        />*/}
            {/*      )}*/}
            {/*    />*/}
            {/*  </Box>*/}

            {/*  <Box className={classes.inputOuter} sx={{ mt: "0px !important" }}>*/}
            {/*    <Controller*/}
            {/*      name={"facebook"}*/}
            {/*      control={control}*/}
            {/*      rules={{ required: false }}*/}
            {/*      render={({ field }) => (*/}
            {/*        <UI.TextField*/}
            {/*          {...field}*/}
            {/*          startAdornment={*/}
            {/*            <InputAdornment position="start">*/}
            {/*              <Box*/}
            {/*                component={"img"}*/}
            {/*                src={facebookIcon}*/}
            {/*                alt="facebook-icon"*/}
            {/*                width={"35px"}*/}
            {/*                height={"35px"}*/}
            {/*                sx={{ mr: "3px" }}*/}
            {/*              />*/}
            {/*            </InputAdornment>*/}
            {/*          }*/}
            {/*          placeholder={"URL"}*/}
            {/*        />*/}
            {/*      )}*/}
            {/*    />*/}
            {/*  </Box>*/}
            {/*</Grid>*/}
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <ResetPasswordButton user={user} />
              </Box>
            </Grid>
          </Grid>

          <Box
            sx={{
              width: "100%",
              pb: "60px",
              pt: { lg: "50px", md: "40px", tablet: "30px", mobile: "20px" },
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: { mobile: "100%", tablet: "230px", lg: "210px" } }}>
              <UI.Button
                // disabled={!!loading}
                title={t("buttons.save")}
                type={"primary"}
              />
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default Index;
