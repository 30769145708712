import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import List from "./List";
import CustomHooks from "../../../../../@components/hooks";
import { useParams } from "react-router-dom";
import useService from "../../useService";
import confettiAnimation from "../../../../../static/confetti-animation.json";
import confettiAnimation2 from "../../../../../static/confetti2.json";
import Lottie from "lottie-react";
import { useUserContext } from "../../../../../@components/userContext";
import NotLoggedInDialog from "../NotLoggedInDialog";
import LogInDialogFlow from "../../../../../profile/dialogs/LogInDialogFlow";

const Index = ({ DAO, onFilterCollections }) => {
  const { albumId } = useParams();
  const service = useService(DAO?.id);
  const [showNotLoggedInDialog, setShowNotLoggedInDialog] = useState(false);
  const [triggerLoginDialog, setTriggerLoginDialog] = React.useState(null);

  const confettiRef = React.useRef(null);
  const confettiRef2 = React.useRef(null);
  const { user: loggedInUser } = useUserContext();
  const { data: cards, refetch: refetchCards } = CustomHooks.useFetch(
    ["fetch_cards", albumId],
    () => service.cards(albumId)
  );

  useEffect(() => {
    if (loggedInUser) {
      setShowNotLoggedInDialog(false);
    } else {
      setShowNotLoggedInDialog(true);
    }
  }, [loggedInUser]);

  const { mutate: submitCard } = CustomHooks.usePost(service.submitCard, {
    onSuccess: async () => {
      triggerConfetti();
      await refetchCards(albumId);
    },
  });

  const triggerConfetti = () => {
    confettiRef.current?.goToAndPlay(0);
    confettiRef2.current?.goToAndPlay(0);
  };

  const handleDrop = React.useCallback(
    (index, data) => {
      if (data.spaceItem.album_card.length === 0) {
        submitCard({
          album_id: albumId,
          album_space_type: "Collection",
          space_id: data.spaceItem.id,
          nft_id: data.droppedItem.nft.id,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cards]
  );

  return (
    <>
      <Paper
        onClick={(e) => {
          e.stopPropagation();
        }}
        sx={{
          width: "100%",
          py: { md: "50px", mobile: "20px" },
          background: "transparent",
        }}
      >
        {cards && (
          <List
            refetch={() => refetchCards(albumId)}
            DAO={DAO}
            items={cards}
            handleDrop={handleDrop}
            onFilterCollections={onFilterCollections}
          />
        )}
      </Paper>
      <Lottie
        lottieRef={confettiRef}
        autoplay={false}
        loop={false}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          pointerEvents: "none",
        }}
        resizeMode="cover"
        animationData={confettiAnimation}
      />
      <Lottie
        lottieRef={confettiRef2}
        autoplay={false}
        loop={false}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          pointerEvents: "none",
        }}
        resizeMode="cover"
        animationData={confettiAnimation2}
      />
      {showNotLoggedInDialog && (
        <NotLoggedInDialog
          onClose={() => {
            setShowNotLoggedInDialog(false);
          }}
          onConfirm={() => {
            setShowNotLoggedInDialog(false);
            setTriggerLoginDialog(Math.random());
          }}
        />
      )}
      <LogInDialogFlow
        onLogin={() => {
          refetchCards(albumId);
        }}
        trigger={triggerLoginDialog}
        onClose={() => setTriggerLoginDialog(null)}
      />
    </>
  );
};

export default Index;
