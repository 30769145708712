import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import CloudUpload from "@mui/icons-material/CloudUpload";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import linkedInLogo from "../../static/linkedin.png";
import discordLogo from "../../static/discord.png";
import twitterLogo from "../../static/twitter.png";
import telegramLogo from "../../static/telegram.png";
import instagramLogo from "../../static/instagram.png";
import facebookLogo from "../../static/facebook.png";
import youtubeIcon from "../../static/youtube-icon.png";
import spotifyIcon from "../../static/spotify-icon.png";
import { useHistory } from "react-router-dom";
import { useUserRole } from "./context";
import useExternalURL from "../../@components/hooks/useExternalURL";
import SettingsButton from "./SettingsButton";
import UI from "../../@components/UI";
import CustomHooks from "../../@components/hooks";
import { useSnackbar } from "notistack";
import useService from "./settings/general/useService";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import DescriptionDialog from "./DescriptionDialog";
import { create as ipfsHttpClient } from "ipfs-http-client";
import useMediaQuery from "@mui/material/useMediaQuery";

const projectId = "2FJrR2634gDmft79seWtyfxalex";
const projectSecret = "7919990e44d516f4c2cbeb92b9df199a";

const auth =
  "Basic " + Buffer.from(projectId + ":" + projectSecret).toString("base64");

const client = ipfsHttpClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: auth,
  },
});

const useStyles = makeStyles((theme) => ({
  text: {
    display: "inline",
    width: "100%",
    fontSize: "14px",
    lineHeight: "1.5",
  },
  readHide: {
    cursor: "pointer",
    opacity: 0.8,
    fontWeight: "700",
  },
}));

const ReadMore = (props) => {
  const { t } = useTranslation();
  const { children, maxTextLength = 55, onShowMore } = props;
  const classes = useStyles();
  const text = children;
  const [isReadMore] = React.useState(true);
  const handleShowMore = () => {
    if (isReadMore) {
      onShowMore();
    }
  };

  return (
    <Typography
      variant={"subtitle1"}
      {...props}
      sx={{ mb: { md: "10px", mobile: "0px" }, mt: "5px", color: "#fff" }}
    >
      <Box component={"span"} className={classes.text}>
        {isReadMore && text.length > maxTextLength
          ? text.slice(0, maxTextLength) + "... "
          : text + " "}
        {text.length > maxTextLength && (
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "center",
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={handleShowMore}
            className={classes.readHide}
          >
            {t("buttons.showMore")}
          </Box>
        )}
      </Box>
    </Typography>
  );
};

const SocialItem = ({ logo, url }) => {
  const { getURL } = useExternalURL();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        mx: { md: "8px", mobile: "5px" },
      }}
    >
      <Link target="_blank" href={getURL(url)} underline="hover">
        <Box
          component={"img"}
          src={logo}
          sx={{
            "&:hover": {
              transform: "scale(1.1)",
            },
            transition: "all 0.5s ease-in-out",
            cursor: "pointer",
            width: { md: "35px", mobile: "30px" },
            height: { md: "35px", mobile: "30px" },
          }}
        />
      </Link>
    </Box>
  );
};

const Socials = ({ DAO }) => {
  return (
    <Container
      maxWidth={"md"}
      sx={{
        textAlign: "center",
        marginRight: { md: "-20px", mobile: "0px" },
        mt: { md: "0px", mobile: "20px" },
        mb: { md: "0px", mobile: "-5px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: { md: "end", mobile: "center" },
        }}
      >
        {DAO?.linkedin && (
          <SocialItem logo={linkedInLogo} url={DAO?.linkedin} />
        )}
        {DAO?.discord && <SocialItem logo={discordLogo} url={DAO?.discord} />}
        {DAO?.twitter && <SocialItem logo={twitterLogo} url={DAO?.twitter} />}
        {DAO?.telegram && (
          <SocialItem logo={telegramLogo} url={DAO?.telegram} />
        )}
        {DAO?.instagram && (
          <SocialItem logo={instagramLogo} url={DAO?.instagram} />
        )}
        {DAO?.facebook && (
          <SocialItem logo={facebookLogo} url={DAO?.facebook} />
        )}
        {DAO?.youtube && <SocialItem logo={youtubeIcon} url={DAO?.youtube} />}
        {DAO?.spotify && <SocialItem logo={spotifyIcon} url={DAO?.spotify} />}
      </Box>
    </Container>
  );
};

const BrandInfo = ({ DAO }) => {
  const history = useHistory();
  const { isBrandMember } = useUserRole();
  return (
    <Box
      onClick={() => {
        history.push(`/brand/${DAO?.id}`);
      }}
      sx={{
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        mb: { md: "25px", mobile: "5px" },
      }}
    >
      <Paper
        sx={{
          borderRadius: "50%",
          background: "transparent",
          width: { md: "40px", mobile: "32px" },
          height: { md: "40px", mobile: "32px" },
          display: "flex",
          mt: "0px",
          overflow: "hidden",
          transition: "all 0.5s ease-in-out",
          "&:hover": {
            transform: "scale(1.07)",
          },
        }}
      >
        {DAO?.avatar_url ? (
          <Box
            component={"img"}
            sx={{
              width: "100%",
              height: "auto",
              margin: "0 auto",
              textAlign: "center",
            }}
            src={DAO?.avatar_url}
            alt={"dao-logo"}
          />
        ) : (
          <Skeleton variant={"circle"} width={50} height={50} />
        )}
      </Paper>
      {DAO ? (
        <Box
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                maxWidth: "610px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                ml: { md: "10px", mobile: "10px" },
                textAlign: { md: "left", mobile: "center" },
                display: { md: "block" },
                color: "#fff",
              }}
              variant={"h4"}
            >
              <UI.OverflowTip>{DAO?.name}</UI.OverflowTip>
            </Typography>
          </Box>
          {isBrandMember && <SettingsButton DAO={DAO} />}
        </Box>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Skeleton
            sx={{ ml: "40px" }}
            variant={"text"}
            width={200}
            height={50}
          />
        </Box>
      )}
    </Box>
  );
};

const Header = ({ DAO }) => {
  const history = useHistory();
  const { i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isBrandMember } = useUserRole();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const service = useService(DAO?.id);

  //const [uploadedImage, setUploadedImage] = useState(null);
  const [image, setImage] = useState(DAO?.header_image);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDescriptionDialog, setShowDescriptionDialog] =
    React.useState(false);
  const brandName = DAO?.name;

  const { mutate: saveSettings } = CustomHooks.usePost(service.save, {
    onSuccess: () => {
      enqueueSnackbar("Settings succesfully updated", {
        variant: "success",
      });
    },
  });

  useEffect(() => {
    if (DAO) {
      setImage(DAO.header_image);
    }
  }, [DAO]);

  // Handle image upload
  const handleImageUpload = async (event) => {
    setIsLoading(true);
    const file = event.target.files[0];
    if (file) {
      const added = await client.add(file, {
        progress: (prog) => console.log(`received: ${prog}`),
      });
      const url = `https://hazellabs.infura-ipfs.io/ipfs/${added.path}`;
      console.log({ ipfs: url, file });
      setImage(url);
      setIsLoading(false);
      setIsUploading(true);
    }
  };

  // Confirm the uploaded image
  const handleConfirm = () => {
    setImage(image);
    setIsUploading(false);
    // Add your backend API call here to save the image
    saveSettings({ header_image: image });
  };

  // Cancel the upload and revert the changes
  const handleCancel = () => {
    setImage(DAO?.header_image);
    setIsUploading(false);
  };

  const handleShowMoreClick = () => {
    setShowDescriptionDialog(true);
  };

  return (
    <>
      <Box
        component={"img"}
        src={image}
        sx={{
          position: "absolute",
          top: { md: "-100px", mobile: "50px" },
          maxHeight: "600px",
          minHeight: "500px",
          filter: "blur(100px)",
          width: "100%",
          opacity: { md: "0.6", mobile: "0.8" },
        }}
      />
      <Box
        onClick={() => {
          history.push(`/brand/${DAO?.id}`);
        }}
        sx={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          width: "100%",
          paddingLeft: { md: "80px", mobile: "0px" }, // SideMenu width
          paddingTop: { md: "50px", mobile: "10px" },
        }}
      >
        <Box
          sx={{
            px: { lg: "75px", md: "45px", tablet: "25px", mobile: "10px" },
            width: "100%",
            position: "relative",
          }}
        >
          {isLoading && (
            <Box
              sx={{
                width: "100%",
                height: "500px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <UI.Busy.Indicator
                width={isMobile ? "100px" : "160px"}
                height={isMobile ? "100px" : "160px"}
              />
            </Box>
          )}
          {DAO ? (
            <Box>
              {!isLoading && image && (
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    height: {
                      lg: "auto",
                      md: "380px",
                      tablet: "350px",
                      mobile: "400px",
                    },
                    maxHeight: "500px",
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      background:
                        "linear-gradient(0deg, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0.15) 30%, rgba(0,0,0,0) 100%)",
                      zIndex: 1,
                    }}
                  />
                  <Box
                    component={"img"}
                    src={image}
                    sx={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                      zIndex: 0,
                    }}
                  />
                </Box>
              )}
            </Box>
          ) : (
            <Skeleton
              variant={"rectangular"}
              height={500}
              sx={{ mb: "30px" }}
            />
          )}

          {/* Overlay for upload icon and text */}
          {isBrandMember && (
            <Box
              onClick={() => document.getElementById("upload-input").click()}
              sx={{
                position: "absolute",
                top: 0,
                zIndex: 3,
                left: { md: 75, mobile: 10 },
                right: { md: 75, mobile: 10 },
                bottom: 0,
                borderRadius: "8px",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                opacity: 0,
                transition: "opacity 0.3s",
                cursor: "pointer",
                "&:hover": {
                  opacity: 1,
                },
              }}
            >
              <Box textAlign="center">
                <CloudUpload sx={{ fontSize: 50 }} />
                <Typography variant="h6" sx={{ color: "#fff" }}>
                  Upload New Image
                </Typography>
              </Box>
            </Box>
          )}

          <input
            accept="image/*"
            style={{ display: "none" }}
            id="upload-input"
            type="file"
            onChange={handleImageUpload}
          />

          {isUploading && (
            <Box
              sx={{
                position: "absolute",
                top: "30px",
                right: { md: "95px", mobile: "50%" },
                transform: { mobile: "translateX(50%)", md: "none" },
                display: "flex",
                gap: "10px",
              }}
            >
              <UI.Button
                type="primary"
                color="primary"
                sx={{ zIndex: 10 }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleConfirm();
                }}
                title={"Confirm"}
              />
              <UI.Button
                sx={{ zIndex: 10 }}
                type="secondary"
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleCancel();
                }}
                title={"Cancel"}
              />
            </Box>
          )}
          <Box
            sx={{
              zIndex: 10,
              position: "absolute",
              bottom: { md: "0px", mobile: "-40px" },
              width: { md: "calc(100% - 150px)", mobile: "calc(100% - 20px)" },
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: { md: "flex", mobile: "block" },
                pl: { lg: "45px", md: "25px", mobile: "15px" },
                pr: { lg: "50px", md: "30px", mobile: "15px" },
                pb: { md: "5px", mobile: "45px" },
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { md: "flex-start", mobile: "center" },
                }}
              >
                <BrandInfo DAO={DAO} />
              </Box>
              <Box
                sx={{
                  display: "block",
                  alignItems: "flex",
                  mr: { lg: 0, md: "-60px", tablet: 0 },
                  mb: "20px",
                }}
              >
                <Box sx={{ display: { md: "block", mobile: "none" } }}>
                  <Socials DAO={DAO} />
                </Box>
                <ReadMore
                  sx={{
                    marginTop: "12px",
                    whiteSpace: "collapse",
                    display: { md: "block", mobile: "none" },
                  }}
                  onShowMore={handleShowMoreClick}
                >
                  {DAO?.description_slo !== null && i18n.language === "sl"
                    ? DAO?.description_slo
                    : DAO?.description || ""}
                </ReadMore>
                <DescriptionDialog
                  open={showDescriptionDialog}
                  onClose={() => setShowDescriptionDialog(false)}
                  brandName={brandName}
                >
                  {DAO?.description_slo !== null && i18n.language === "sl"
                    ? DAO?.description_slo
                    : DAO?.description || ""}
                </DescriptionDialog>
                <Box sx={{ display: { md: "none", mobile: "block" } }}>
                  <Socials DAO={DAO} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {DAO?.description && (
        <Box
          sx={{
            width: "100%",
            paddingLeft: { md: "80px", mobile: "0px" },
            position: "relative",
          }}
        >
          <Box
            sx={{
              px: { md: "75px", mobile: "10px" },
              my: "20px",
              textAlign: "center",
            }}
          ></Box>
        </Box>
      )}
    </>
  );
};

export default Header;
