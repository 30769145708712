import React from "react";
import Card from "./Card";
import EditCard from "./EditCard";
import Locked from "./Locked";
import Overlay from "./Overlay";

const Index = () => {
  return <></>;
};

Index.Card = Card;
Index.EditCard = EditCard;
Index.Locked = Locked;
Index.Overlay = Overlay;

export default Index;
