import React from "react";
import UI from "../../../../../../@components/UI";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MaterialDialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Link from "@mui/material/Link";
import CustomHooks from "../../../../../../@components/hooks";
import useService from "../../../useService";
import { useParams } from "react-router-dom";
import useScratchCard from "./useScratchCard";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  base: {
    cursor: "pointer",
    height: "191px",
    position: "relative",
    width: "191px",
    lineHeight: "150px",
    textAlign: "center",
    zIndex: 1,
  },
  scratch: {
    "-webkit-tap-highlight-color": "rgba(0, 0, 0, 0)",
    "-webkit-touch-callout": "none",
    "-webkit-user-select": "none",
    cursor: "pointer",
    height: "191px",
    position: "absolute",
    top: "165px",
    left: ({ isMobile }) => {
      return isMobile ? "70px" : "179px";
    },
    zIndex: 2,
  },
});

const Dialog = ({
  albumSpace,
  DAO,
  open,
  onClose,
  data,
  refetch,
  triggerConfetti,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const { albumId } = useParams();
  const service = useService(DAO?.id);
  const classes = useStyles({ isMobile });
  const { canvasRef } = useScratchCard(open);

  const { mutate: submitCard } = CustomHooks.usePost(service.submitCard, {
    onSuccess: async () => {
      await refetch();
      triggerConfetti();
    },
  });

  React.useEffect(() => {
    if (open && albumSpace.album_card.length === 0) {
      submitCard({
        album_id: albumId,
        album_space_type: albumSpace.album_space_type,
        space_id: data.id,
      });
    }

    // eslint-disable-next-line
  }, [open]);

  return (
    <>
      <MaterialDialog
        PaperProps={{
          sx: {
            background: (theme) => theme.palette.dialog.background,
            backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
            position: "relative",
            padding: { md: "60px 20px", mobile: "60px 20px" },
            width: "550px",
            margin: { mobile: "15px", md: "0px" },
          },
        }}
        open={open}
        onClose={onClose}
      >
        <CloseIcon
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 15,
            top: 15,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />
        <DialogContent
          sx={{
            p: "0px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Box sx={{ mt: "10px" }}>
            <Typography variant={"h3"}>
              {t("brands.albums.createCard.discount.scratch")}
            </Typography>
            <Typography variant={"subtitle1"} sx={{ mt: "10px" }}>
              {t("brands.albums.createCard.discount.prize")}
            </Typography>
          </Box>
          <Box sx={{ mt: "25px" }} className={classes.base}>
            <Box
              sx={{
                background: "#F2F2F3",
                width: "190px",
                height: "190px",
                borderRadius: "100px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant={"h2"}
                sx={{
                  lineHeight: "50px",
                  color: "#000",
                  fontSize: "56px !important",
                }}
              >
                {`${data.data_json.value}%`}
              </Typography>
              <Typography
                variant={"subtitle4"}
                sx={{
                  mt: "15px",
                }}
              >
                {`Discount`}
              </Typography>
              <Typography
                variant={"h6"}
                sx={{
                  color: "rgba(122, 126, 146, 0.89)",
                  opacity: 0.8,
                }}
              >
                {`${data.data_json.code}`}
              </Typography>
            </Box>
          </Box>
          <canvas
            ref={canvasRef}
            className={classes.scratch}
            width="191"
            height="191"
          ></canvas>
          <Box sx={{ mt: "23px" }}>
            <Typography variant={"subtitle3"}>
              {data.data_json.description}
            </Typography>
          </Box>

          <Box sx={{ mt: "35px" }}>
            <Link href={data.data_json.external_link} target="_blank">
              <UI.Button
                sx={{
                  width: "100%",
                  height: "50px",
                  background: "#944DFF",
                  "&:hover": {
                    background: "#944DFF",
                  },
                }}
                title={t("brands.albums.createCard.discount.visitWebsite")}
              />
            </Link>
          </Box>
        </DialogContent>
      </MaterialDialog>
    </>
  );
};

const Action = ({
  element: Component,
  data,
  DAO,
  albumSpace,
  refetch,
  triggerConfetti,
}) => {
  const [open, setOpen] = React.useState(null);

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Box
        sx={{ cursor: "pointer", height: "100%", width: "100%" }}
        onClick={() => setOpen(!open)}
      >
        {Component}
      </Box>

      <Dialog
        refetch={refetch}
        DAO={DAO}
        triggerConfetti={triggerConfetti}
        open={open}
        albumSpace={albumSpace}
        onClose={() => setOpen(false)}
        data={data}
      />
    </Box>
  );
};

Dialog.Action = Action;

export default Dialog;
