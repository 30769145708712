import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import UI from "../../@components/UI";
import Grid from "@mui/material/Grid";
import MaterialDialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import { Controller, useForm, useWatch } from "react-hook-form";
import Alert from "@mui/material/Alert";
import useService from "../useService";
import CustomHooks from "../../@components/hooks";
import useHandleErrors from "../../@components/hooks/useHandleErrors";

const SingleSignOnDialog = ({ open, onClose, onSuccess }) => {
  const { t } = useTranslation();
  const { handleApiError } = useHandleErrors();
  const [isLoading, setIsLoading] = React.useState(null);
  const service = useService();

  const { mutate: getPassword } = CustomHooks.usePost(service.getOTP, {
    onSuccess: async () => {
      setIsLoading(false);
      reset();
      onSuccess(watchedEmail);
    },
    onError: (e) => {
      setIsLoading(false);
      reset();
      handleApiError(e);
    },
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
    mode: "onSubmit",
  });

  const watchedEmail = useWatch({ control, name: "email" });

  const onSubmit = async (form) => {
    setIsLoading(true);
    try {
      await getPassword(form.email);
    } catch (e) {
      setIsLoading(false);
      reset();
      handleApiError(e);
    }
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <MaterialDialog
      fullWidth={true}
      PaperProps={{
        sx: {
          position: "relative",
          padding: { tablet: "40px 60px 50px", mobile: "28px 10px 30px" },
          width: "650px",
          textAlign: "center",
          margin: { mobile: "10px" },
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent sx={{ p: { mobile: "5px 10px 17px", md: "0px" } }}>
        <CloseIcon
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 20,
            top: 30,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />

        <Typography variant={"h3"} sx={{ textAlign: "center", mb: "10px" }}>
          {t("profile.dialogs.singleSignOnDialog.title")}
        </Typography>
        <Typography variant={"subtitle2"} sx={{ textAlign: "center" }}>
          {t("profile.dialogs.singleSignOnDialog.subtitle")}
        </Typography>
        <Grid
          container
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Grid item mobile={12} md={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ mt: "25px" }}>
                <Controller
                  name={"email"}
                  rules={{
                    required: true,
                    pattern: {
                      value:
                        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: t("profile.dialogs.createAccount.emailPattern"),
                    },
                  }}
                  control={control}
                  render={({ field }) => (
                    <UI.TextField
                      {...field}
                      fullWidth
                      placeholder={t("profile.dialogs.logIn.email")}
                    />
                  )}
                />
                <Box sx={{ mt: "8px" }}>
                  {errors.email && (
                    <Alert variant="outlined" severity="error">
                      {errors.email?.type === "pattern" && errors.email.message}
                      {errors.email?.type === "required" &&
                        t("profile.dialogs.createAccount.emailRequired")}
                    </Alert>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  mt: { md: "34px", mobile: "24px" },
                  px: "15px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <UI.Button
                  disabled={Object.keys(errors).length}
                  sx={{ width: "340px" }}
                  title={t("profile.dialogs.singleSignOnDialog.button")}
                  type={"primary"}
                />
              </Box>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <UI.Busy.FullscreenIndicator show={isLoading}>
        <Typography variant={"subtitle1"}>
          {t("profile.dialogs.loginWithWallet.loading")}
        </Typography>
        <Typography variant={"subtitle1"}>
          {t("profile.dialogs.loginWithWallet.loading2")}
        </Typography>
      </UI.Busy.FullscreenIndicator>
    </MaterialDialog>
  );
};

const Action = ({ element: Component, onSuccess }) => {
  const [open, setOpen] = React.useState(null);

  return (
    <>
      <Box component={"span"} onClick={() => setOpen(!open)}>
        {Component}
      </Box>

      <SingleSignOnDialog
        open={open}
        onClose={() => setOpen(false)}
        onSuccess={(address) => {
          onSuccess(address);
          setOpen(false);
        }}
      />
    </>
  );
};

SingleSignOnDialog.Action = Action;

export default SingleSignOnDialog;
