import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import useService from "./useService";
import CustomHooks from "../../../@components/hooks";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../@components/UI";

const Item = ({ item }) => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <Box
      onClick={() => {
        history.push({
          pathname: `${location.pathname}/album/${item.id}`,
          state: { scrollToTop: false },
        });
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "150px",
          borderRadius: "10px",
          cursor: "pointer",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            opacity: "0.8",
            transform: { md: "scale(1.01)", mobile: "none" },
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            transition: "all 0.3s ease-in-out",
            backgroundImage: `url(${item.avatar_url})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "10px",
            position: "relative",
            "::before": {
              content: '""',
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "100%",
              background: "rgba(0, 0, 0, 0.35)",
              zIndex: 1,
              borderRadius: "10px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "2",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                variant={"h4"}
                sx={{
                  color: "#fff",
                }}
              >
                {item.name}
              </Typography>
              {item.is_hidden && (
                <Typography
                  variant={"subtitle4"}
                  sx={{ color: "#fff", ml: "4px" }}
                >
                  {"(" + t("commons.private") + ")"}
                </Typography>
              )}
            </Box>
            <Typography
              variant={"subtitle3"}
              sx={{
                color: "#fff",
              }}
            >
              {t("brands.albums.collected")} {item.amount_collected}/
              {item.max_collect_amount}
            </Typography>
          </Box>
        </Box>
        {item.is_hidden && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              inset: 0,
              backgroundColor: "rgba(0, 0, 0, 0.05)",
              textAlign: "center",
              borderRadius: "10px",
            }}
          ></Box>
        )}
      </Box>
    </Box>
  );
};

const AlbumSlider = ({ DAO }) => {
  const albumService = useService(DAO?.id);
  const { data: items, isLoading } = CustomHooks.useFetch(
    ["albums", { id: DAO?.id }],
    albumService.list
  );

  return (
    <>
      {!isLoading && items && items.length === 0 && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <UI.NoResults text={"No albums found"} />
        </Box>
      )}
      <Swiper
        grabCursor={true}
        slidesPerView={items?.length > 1 ? 1.2 : 1}
        breakpoints={{
          600: {
            slidesPerView: 2,
          },
          1300: {
            slidesPerView: 3,
          },
          1700: {
            slidesPerView: 4,
          },
        }}
        spaceBetween={20}
        slidesPerGroup={1}
        autoplay={{
          delay: 6000,
          disableOnInteraction: true,
          pauseOnMouseEnter: false,
        }}
        loop={true}
        pagination={true}
        modules={[Autoplay]}
        className="album-slider"
      >
        {items &&
          items.map((item, index) => (
            <SwiperSlide key={index}>
              <Item item={item} onClick={() => {}} />
            </SwiperSlide>
          ))}
      </Swiper>
    </>
  );
};

export default AlbumSlider;
