import React, { useState } from "react";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";

const FlipCardContainer = styled(Card)`
  width: 100%;
  height: 100%;
  perspective: 1000px;
  background: transparent;
  transform-style: preserve-3d;
  box-shadow: none;
  will-change: transform; /* Optimizes for transformations */
  &:hover {
    transition: all 0.2s ease-in-out;
  }
`;

const FlipCardInner = styled("div", {
  shouldForwardProp: (prop) => prop !== "isFlipped",
})(({ isFlipped }) => ({
  position: "relative",
  width: "100%",
  height: "100%",
  textAlign: "center",
  transition: "transform 0.3s",
  transformStyle: "preserve-3d",
  transform: isFlipped ? "rotateY(180deg)" : "none",
  "will-change": "transform", // Improve performance
}));

const FlipCardSide = styled("div")`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Improved handling for Safari */
  backface-visibility: hidden;
  transform: translateZ(0); /* Double-buffering technique */
`;

const FlipCardFront = styled(FlipCardSide)`
  height: 100%;
  width: 100%;
`;

const FlipCardBack = styled(FlipCardSide)`
  height: 100%;
  width: 100%;
  transform: rotateY(180deg) translateZ(0); /* Double-buffering technique */
`;

const AlbumFlipCard = ({ frontComponent, backComponent, isConfirmed }) => {
  const [isFlipped, setIsFlipped] = useState(isConfirmed);

  const handleClick = (e) => {
    e.stopPropagation();
    setIsFlipped(!isFlipped);
  };

  return (
    <FlipCardContainer
      onClick={handleClick}
      sx={{
        height: "100%",
        width: "100%",
        boxShadow: (theme) => theme.palette.album.boxShadow,
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          backgroundColor: (theme) => theme.palette.bgTransparent.secondary,
          transform: "scale(1.025)",
        },
      }}
    >
      <FlipCardInner isFlipped={isFlipped}>
        <FlipCardFront>{frontComponent}</FlipCardFront>
        <FlipCardBack>{backComponent}</FlipCardBack>
      </FlipCardInner>
    </FlipCardContainer>
  );
};

export default AlbumFlipCard;
