import MaterialDialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React from "react";

const DescriptionDialog = ({ open, onClose, children, brandName }) => {
  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          padding: { md: "40px 30px", mobile: "30px 5px" },
          width: "600px",
          margin: { mobile: "0 10px" },
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
        },
      }}
      open={open}
      onClose={onClose}
    >
      <CloseIcon
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 15,
          top: 15,
          cursor: "pointer",
          "&:hover": {
            transition: "all 0.1s ease 0s",
            transform: "scale(1.1) rotate(-90deg)",
          },
        }}
      />
      <DialogContent sx={{ p: "0px" }}>
        <Typography
          variant={"h4"}
          sx={{ textAlign: "center", fontSize: "20px", mt: "10px" }}
        >
          {brandName}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            justifyContent: "center",
            mt: "40px",
            width: "80%",
            margin: "20px auto",
          }}
        >
          <Typography
            variant={"subtitle2"}>
            {children}
          </Typography>
        </Box>
      </DialogContent>
    </MaterialDialog>
  );
};

export default DescriptionDialog;