import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MaterialDialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import infoImg from "../src/static/info_instagram.png";

const UseExternalBrowserDialog = () => {
  const { t } = useTranslation();

  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          width: "550px",
          margin: { mobile: "15px", md: "0px" },
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
        },
      }}
      open={true}
    >
      <DialogTitle sx={{ p: "30px 24px 5px" }}>
        <Typography variant={"h3"} sx={{ fontSize: "18px !important" }}>
          {t("externalBrowserDialog.title")}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ mt: "10px", p: "0px 24px 5px 24px" }}>
          <Typography variant={"subtitle2"}>
            {t("externalBrowserDialog.subtitle")}
          </Typography>
          <Typography variant={"subtitle2"} sx={{ mt: "10px" }}>
            {t("externalBrowserDialog.subtitle3")}
          </Typography>
        </Box>
        <Box sx={{ p: "5px" }}>
          <Box component={"img"} src={infoImg} sx={{ width: "100%" }} />
        </Box>
      </DialogContent>
    </MaterialDialog>
  );
};

export default UseExternalBrowserDialog;
