import React from "react";
import Card from "./Card";
import Dialog from "./Dialog";
import Overlay from "./Overlay";
import Locked from "./Locked";
import FrontSide from "../FrontSide";
import BackSide from "./BackSide";

const Index = () => {
  return <></>;
};

Index.Card = Card;
Index.Dialog = Dialog;
Index.FrontSide = FrontSide;
Index.BackSide = BackSide;
Index.Overlay = Overlay;
Index.Locked = Locked;

export default Index;
