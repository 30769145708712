import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Box } from "@material-ui/core";
import brandsIcon from "../../../static/brands-icon.png";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import { useTheme } from "@mui/material/styles";
import Auth from "../../auth";
import collectionsIcon from "../../../static/collection-icon-dark.png";

const UserAvatar = ({ user }) => {
  const theme = useTheme();
  const { logout } = Auth.useAuth();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        sx={{ mr: "0px", display: { mobile: "flex", md: "flex" }, pb: "0px", backgroundColor: "transparent !Important" }}
        onClick={handleClick}
        size="large"
        edge="end"
        aria-label="account of current user"
        aria-haspopup="true"
      >
        <Tooltip
          title={user?.email_verified ? "" : t("profile.avatar.notVerified")}
          arrow
        >
          <Badge
            badgeContent={"!"}
            overlap="circular"
            color="error"
            invisible={user?.email_verified && user?.smart_address !== null}
          >
            <Avatar
              sx={{
                transition: "box-shadow 0.3s",
                width: "48px",
                height: "48px",
                boxShadow:
                  user?.email_verified && user?.smart_address !== null
                    ? "unset"
                    : "0 0 0 2px #d32f2f",
                "&:hover": {
                  boxShadow:
                    user?.email_verified && user?.smart_address !== null
                      ? "0 0 0 2px #944DFF"
                      : "0 0 0 2px #d32f2f",
                },
              }}
              src={user?.avatar_url}
            />
          </Badge>
        </Tooltip>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            pt: "4px",
            pb: "6px",
            backgroundColor: (theme) => theme.palette.dialog.background,
            backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
            "& .MuiAvatar-root": {
              width: 24,
              height: 24,
              ml: "8px",
              mr: 1,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            history.push({
              pathname: "/brand/apply",
              state: { scrollToTop: true },
            });
          }}
          sx={{
            p: "0 10px",
            background: (theme) => theme.palette.bgTransparent.quaternary,
            borderRadius: (theme) => theme.borderRadius.secondary,
            m: "2px 16px",
            transition: "all 0.2s ease-in-out",
            color: (theme) => theme.palette.text.primary,
            "&:hover": {
              background: (theme) => theme.palette.bgTransparent.secondary,
            },
          }}
        >
          <ListItemIcon sx={{ mr: "7px" }}>
            <DesignServicesIcon sx={{ fontSize: "22px !important" }} />
          </ListItemIcon>
          <Typography
            variant={"subtitle3"}
            sx={{
              color: (theme) => theme.palette.text.primary,
            }}
          >
            {t("appBar.userMenu.createBrand")}
          </Typography>
        </MenuItem>
        <Divider
          sx={{
            borderColor: theme.palette.bgTransparent.primary,
            m: "27px 10px",
            borderWidth: "0.5px",
            mb: "10px !important",
          }}
        />
        <MenuItem
          onClick={() => {
            handleClose();
            history.push({
              pathname: "/profile/nfts",
              state: { scrollToTop: true },
            });
          }}
          sx={{
            minHeight: "42px",
            mx: "8px",
            borderRadius: "5px",
          }}
        >
          <ListItemIcon sx={{ mr: "8px" }}>
            <Box
              component={"img"}
              src={collectionsIcon}
              sx={{
                width: "22px",
                opacity: "0.7",
                filter: theme.palette.type === "dark" ? "invert(1)" : "unset",
              }}
            />
          </ListItemIcon>
          <Typography
            variant={"subtitle3"}
            sx={{
              color: (theme) => theme.palette.text.primary,
            }}
          >
            {t("appBar.myNFTs")}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            history.push({
              pathname: "/profile/brands",
              state: { scrollToTop: true },
            });
          }}
          sx={{
            minHeight: "42px",
            mx: "8px",
            borderRadius: "5px",
          }}
        >
          <ListItemIcon sx={{ mr: "8px" }}>
            <Box
              component={"img"}
              src={brandsIcon}
              sx={{
                width: "21px",
                opacity: "0.7",
                filter: theme.palette.type === "dark" ? "invert(1)" : "unset",
              }}
            />
          </ListItemIcon>
          <Typography
            variant={"subtitle3"}
            sx={{
              color: (theme) => theme.palette.text.primary,
            }}
          >
            {t("profile.home.myBrands")}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            history.push({
              pathname: "/profile/finance",
              state: { scrollToTop: true },
            });
          }}
          sx={{
            minHeight: "42px",
            mx: "8px",
            borderRadius: "5px",
          }}
        >
          <ListItemIcon sx={{ mr: "7px" }}>
            <AccountBalanceWalletIcon sx={{ fontSize: "22px !important" }} />
          </ListItemIcon>
          <Typography
            variant={"subtitle3"}
            sx={{
              color: (theme) => theme.palette.text.primary,
            }}
          >
            {t("profile.finance.title")}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            history.push({
              pathname: "/profile/settings",
              state: { scrollToTop: true },
            });
          }}
          sx={{
            minHeight: "42px",
            mx: "8px",
            borderRadius: "5px",
          }}
        >
          <ListItemIcon sx={{ mr: "7px" }}>
            <Settings sx={{ fontSize: "22px !important" }} />
          </ListItemIcon>
          <Typography
            variant={"subtitle3"}
            sx={{
              color: (theme) => theme.palette.text.primary,
            }}
          >
            {t("appBar.userMenu.settings")}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            logout();
          }}
          sx={{
            p: "0 10px",
            background: "#944dff",
            borderRadius: (theme) => theme.borderRadius.secondary,
            m: "0 20px",
            mt: "12px",
            minHeight: "38px",
            transition: "all 0.2s ease-in-out",
            "&:hover": {
              color: "white",
              background: "#b185f2",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "0 20px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                mr: "7px",
              }}
            >
              <Logout sx={{ color: "white", width: "18px" }} />
            </Box>
            <Typography variant={"h8"} sx={{ color: "white" }}>
              {t("appBar.userMenu.logOut")}
            </Typography>
          </Box>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserAvatar;
